.dashboardHeader {
	position: fixed;
	left: 0;
	top: 0;
	width: 50px;
	height: 100%;
	z-index: 99;
	background-color: $white;
	border-bottom: 1px solid $natural-grey;
	border-right: solid 1px $blue-grey;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-weight: 400;
	padding: 0;
	margin: 0;
	justify-content: space-between;
	.trns_flag {
		height: 24px;
		width: 24px;
		display: inline-block;
		vertical-align: middle
	}

	.trns_flag_and_text {
		padding-left: 20px
	}

	.trns_flag_en {
		background: url("../images/Flags/en.png") no-repeat;
	}

	.trns_flag_ru {
		background: url("../images/Flags/ru.png") no-repeat;
	}
	.dropdown-toggler{
		display: flex;
		flex-direction: column;
		align-items: center;
		i{
			margin-top: 1rem;
			margin-left: 0.5rem;
		}
	}
	.menu-list {

		.vertical {
			writing-mode: vertical-lr;
			transform: rotate(180deg);
			white-space: nowrap;
			text-decoration: none;
		}

	}

	.promoatlasLogo {
		display: none;
	}

}

.menu-list {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	justify-content: center;
	text-decoration: none;
}

#login_name {
	text-decoration: none;
}

.headerNav {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	margin-top: 4rem;
}

.promoatlasLogo {
	margin-bottom: 2rem;
	margin-top: 3rem;
}

.admin {
	margin-bottom: 2rem;
}

.rotate-90 {
	transform: rotate(-90deg);
}

body {
	padding-left: 100px;
	padding-right: 50px;
	font-family: 'Gotham', sans-serif;
}

.top-menu {
	display: flex;
	justify-content: space-between;
	margin-top: 3rem;
	margin-bottom: 1rem;
}

.menu-filter {
	margin-bottom: 1rem;
}

.button-accent {
	padding: 1.2rem 2rem;
	background-color: $blue;
	border-radius: 4px;
	color: $white;
	font-size: 14px;
}

.red-button {
	padding: 1.2rem 2rem;
	background-color: $red;
	border-radius: 4px;
	color: $white;
	font-size: 14px;
}

.button-accent:hover {
	color: $white;
}

.border-blue-btn {
	padding: 1.2rem 2rem;
	background-color: transparent;
	border-radius: 3px;
	color: $blue;
	border: 1px solid $blue;
}

.white-text {
	color: white;
}

.button-delete {
	background: none;
	color: $dark-grey;
}

#export_ap {
	padding: 1.2rem 2rem;
}

.button-border {
	color: $blue;
	background-color: transparent;
	padding-right: 2rem;
	outline: none;
	font-size: 15px;
	border: none;
}

.blue-cursor-button {
	color: $blue;
	background-color: transparent;
	outline: none;
	font-size: 15px;
	border: none;
	cursor: pointer;
}

button {
	border-bottom: none;
	border-width: none;
	border-style: none;
}

.name-page {
	font-size: 20px;
	color:$blue;
	font-weight: 500;
}

.name-page-client {
	font-size: 2.4rem;
	color: $blue;
	font-weight: 500;
}

.name-page-grey {
	font-size: 2rem;
	color: $dark-grey;
	font-weight: 500;
	margin-top: 3rem;
	margin-bottom: 2.5rem;
}


#filt_p_client_chosen {
	width: 150px !important;
}

.input-admin-media {
	border: none;
	box-shadow: none;
	margin-bottom: 10px;
	width: 100px;
	display: inline-block;
}

.has-search-icon:before {
	display: inline-block;
	content: "\f002";
	font-family: "FontAwesome";
	color: $light-grey;
	margin-right: .7rem;
}

.has-separator:after {
	display: inline-block;
	content: ' ';
	width: 1px;
	height: 1em;
	background: $blue-grey;
	margin: 0 1rem;
	transform: translateY(25%);
}

.redesign-table {
	overflow-y: hidden;
	overflow-x: scroll;
	td {

		.chosen {
			width: 100%!important;
		}

	}
	thead {

		th {
			border-top: none !important;
			border-bottom: 1px solid $blue-grey !important;
		}

		td {

			> {

				i {
					margin-left: 0.7rem;
				}

			}

		}

	}

}

.redesign-table-title {
	color: $blue;
	font-size: 1.3rem;
	font-weight: 500;
	padding-top: 6rem !important;
}

.title-table-project {
	color: $blue;
	font-size: 1.5rem;
	font-weight: 500;
}

.title-grey-table {
	color: $grey;
	font-size: 1.5rem;
	font-weight: 500;
}

#orders-table {
	margin-top: -3.5rem;
}

.redesign-table thead th:first-child,
.redesign-table thead td:first-child,
.redesign-table tbody td:first-child {
	padding-left: 0;
}

.icon-table {
	color: $blue;
}



.table-condensed {

	thead {

		tr {

			> {

				th {
					font-weight: 400;
				}

			}

		}

	}

}



#paginations {
	margin-top: 2rem !important;
}

.fa-trash:before {
	margin-right: 8px;
}

.icon-status {
	margin-left: 10px;
	color: $grey;
}

.text-right {
	text-align: right;
}

.dropdown-menu {
	box-shadow: 0px 2px 6px $shadow;
}

.modal-mediaplan {
	z-index: 200;
	opacity: 1 !important;
	left: 0;
	margin-left: 6rem;
	top: 30px;
}

.status_list_mediaplan {
	margin-bottom: 3rem;
}

.nocaps_mediaplan {
	font-size: 1.7rem;
	font-weight: 400;
}

.smaller {
	margin-left: 1.5rem;
}

.table-menu.menu-orders {

	th {
		padding-bottom: 3rem;
		font-size: 14px;
		font-weight: 400;
		padding-left: 0;
	}

}

.padding-for-icon {
	padding-right: 8px;
}

.place-icon {
	padding-left: 8px;
}

#client-bill-table thead > tr > th,
#client-bill-table tbody td {
	padding: 10px;
	padding-right: 2rem;
	font-size: 15px;
	padding-left: 0;
	border-top: none;
}

.table-client {
	&.parts-table{
		margin-bottom: 0;
	}
	th {
		border-top: none !important;
	}
	input{
		&:disabled{
			opacity: 0.53;
			background-color: $white !important;
		}
	}


}

#second-table-orders {

	thead {

		> {

			tr {

				> {

					th {
						padding: 2rem 2rem 2rem 0;
						border-bottom: 1px solid $natural-grey;
					}

				}

			}

		}

	}

	tbody {

		> {

			tr {

				> {

					th {
						padding: 2rem 2rem 2rem 0;
						border-bottom: 1px solid $natural-grey;
					}

				}

			}

		}

	}

}

.dropdown-orders {
	margin-left: 8px;
}

.icon-delete {
	color: $red;
	margin: 0;
}

.edit-icon {
	color: $gelb;
}

.slim-text {
	font-size: 14px;
	font-weight: 400;
}

.transparent-button {
	padding: 1.2rem 2rem;
	border: 1px solid $natural-grey;
	border-radius: 3px;
	color: $dark-grey;
	background: transparent;
}

.background-none {
	background: none;
}

.flex {
	display: flex;
}

.btn-transperent{
	background-color: transparent;
	padding-left: 0;
}

.flex-center-v {
	display: flex;
	align-items: center;
}

.flex-spread {
	display: flex;
	justify-content: space-between;
}

.flex-column {
	display: flex;
	flex-direction: column;
}
.flex-space{
	display: flex;
	justify-content: space-between;
}

.date-group{
	div{
		color: $grey;
		font-weight: 500;
		font-size: 15px;
	}
	.react-datepicker-wrapper{
		width: 100% !important;
	}
}
.note-project{
	padding-top: 2rem;
	font-size: 15px;
	color: $light-grey;
}
.w-50{
	width: 50% !important;
}
.w-48{
	width: 48%;
}
.react-datepicker-wrapper{
	width: 100%;
}
.mr-1 {
	margin-right: 1rem;
}

.mr-2 {
	margin-right: 2rem;
}

.mr-07 {
	margin-right: 0.7rem;
}

.ml-2 {
	margin-left: 2rem;
}

.ml-1 {
	margin-left: 1rem;
}

.mr-left-2 {
	margin-left: 2rem;
}

.ml-0 {
	margin-left: 0;
}

.mr-0 {
	margin-right: 0;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mb-3 {
	margin-bottom: 3rem;
}

.mb-05 {
	margin-bottom: 0.5rem;
}

.mt-1 {
	margin-top: 1rem;
}

.mt-3 {
	margin-top: 3rem;
}

.mt-0 {
	margin-top: 0;
}

.mt-2 {
	margin-top: 2rem;
}

.mt-7 {
	margin-top: 7rem;
}

.m-0 {
	margin: 0 !important;
}

.pd-0 {
	padding-left: 0!important;
}

.pr-2 {
	padding-right: 2rem;
}

.pr-0 {
	padding-right: 0;
}

.pt-0 {
	padding-top: 0;
}

.pb-2 {
	padding-bottom: 2rem;
}
.pt-2{
	padding-top: 2rem;
}

.padding-0 {
	padding: 0;
}

.pd-r-30 {
	padding-right: 30px;
}

.left-20 {
	left: 20px;
}
.pseudo-checkbox {
	opacity: 0;
	pointer-events: none;
}

.font-400 {
	font-weight: 400;
}
.btn-download-orders {
	padding: 1.2rem 2rem;
	background-color: $blue;
	border-radius: 4px;
}

.edit_cities {

	.btn-group {
		border: 1px solid $natural-grey;
		margin-top: 1.5rem;
	}

}

.greyConteiner.lastChanges {
	background-color: $white;
}

hr {
	border: none;
	margin-bottom: 3rem;
}
.pd-top {
	padding-top: 2rem !important;
}

.mr-top-3 {
	margin-top: 3rem;
}

.text-comment {
	align-items: center;
}

.table-edit-order {
	background-color: $white !important;
	thead {

		tr {

			th {
				vertical-align: middle;
				color: $dark-grey;
				font-weight: 400;
			}

		}

	}

}

.alert-success {
	background-color: transparent;
	color: $blue;
	border: none;
}

#entity-form {

	.alert {
		padding-top: 0;
	}

}

.table.table {

	td {

		input[type="checkbox"] {
			width: 20px;
		}

	}

}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
	background-color: $white;
}

.pagination > li > a, .pagination > li > span {
	border: none !important;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
	border-radius: 3px;
}

.padjinator_orders {
	float: left;
	padding-left: 0;
}
.comment-order {
	background-color: transparent;
	color: $blue;
	font-size: 15px;
	font-weight: 400;
	padding-left: 0;
}

.text-select-media {
	font-size: 14px;
	font-weight: 400;
}

.grey-button {
	font-size: 14px;
	font-weight: 400;
	border: none;
	color: $dark-grey;
	background: transparent;
	padding-left: 0;
}


.col-sm-12 {
	padding-left: 0 !important;
}


// .mediaplan-table {

// 	.table-filter-row {
// 		display: none;
// 	}

// }

.topScroll {
	display: none;
}

.row.general-control-tableOwner.clearfix.fscr {
	display: none;
}

.save-order {
	border-radius: 3px;
	border: 1px solid;
}

.red-icon {
	color: $red;
}

.lastChanges {

	.row {
		padding-left: 0;
	}

}

.pl-0 {
	padding-left: 0;
}

.pl-1 {
	padding-left: 1rem;
}

.form-horizontal {

	.control-label {
		text-align: left;
	}

}

.control-label.slim-text {

	> {

		label {
			font-weight: 400;
		}

	}

}



.small-text-finance {
	font-size: 2rem;
	color: $light-grey;
}

.chosen-choices {
	border: none !important;
}

.mr-for-icon {
	margin-right: 0.7rem;
}

.w-2 {
	width: 2rem;
}


.pd-left0 {
	padding-left: 0 !important;
}


.name-menu-client {
	font-size: 19px;
	color: $blue !important;
}
.edit-client{
	.table-edit-client {
		width: 85%;
		&.advertising-table{
			td{
				border-bottom: none;
			}
		}
	}
}
.note-grey {
	margin-top: 0.7rem;
	color: $light-grey;
	font-size: 14px;
}

.fat-text {
	font-weight: 500;
	color: $dark-grey;
}

.right-text {
	text-align: right;
	padding-right: 0 !important;
}

.input-edit-client {
	width: 100%;
}

.multiselect.dropdown-toggle.btn {
	background-color: $white !important;
	border: 1px solid $blue-grey !important;
}

.table-fin {

	.chosen-container.chosen-container-single.chosen-container-single-nosearch {
		width: 100% !important;
		border: 1px solid $natural-grey !important;
		height: 4rem;
		border-radius: 3px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.chosen-single {
			width: 100%;
			padding-left: 1rem!important;
		}

		.chosen-results {
			margin-right: 0;
			padding-left: 0;
		}

	}

	.client_transaction_finSchemePart {

		.chosen-container.chosen-container-single.chosen-container-single-nosearch {
			width: inherit!important;
			border: none!important;
		}

	}

	.chosen-single {
		padding-left: 0 !important;
	}

}

.client_transaction_finSchemePart {

	> {

		* {
			margin-right: 2rem!important;
		}

	}

	.has-separator:last-child {
		display: none;
	}

	label {
		margin-bottom: 0;
	}

}

.client_transaction {

	td {

		> {

			label {
				font-weight: 500!important;
			}

		}

	}

}

.plus-button {
	font-size: 2rem;
	color: $blue;
}

.minus-button {
	font-size: 2rem;
	color: $red;
}

.table-admin {

	.form-control.file-caption.kv-fileinput-caption {
		background-color: $white !important;
		padding: 7px;
		border-radius: 3px;
		border: 1px solid $natural-grey;
	}

	> {

		tbody {

			> {

				tr {

					> {

						td {
							border-top: none;
							border-bottom: 1px solid $natural-grey;
							padding-bottom: 1rem;
						}

					}

				}

			}

		}

	}

	.v-100 {
		width: 100px;
	}

	.width20 {
		padding-right: 0;
	}

}

.icon-button {
	background: transparent;
}
.modal-footer-admin {
	padding-left: 0 !important;
	text-align: left;
	border-top: none;
}

.modal-footer-group {
	text-align: left;
	border-top: none;
	padding: 15px 30px;
}

.modal-title-group {
	padding-left: 15px;
}

.admin-table-legal {

	> {

		tbody {

			> {

				tr {

					>td {
						padding-left: 0 !important;
						border-top: none;
					}

				}

			}

		}

	}

}

.admin-table-entity {
	width: 100%;
	.chosen-container {

		.chosen-container-single {
			width: 100%;
		}

	}

	.chosen-single {
		padding-left: 0;
	}

	.input {
		margin-top: 3rem;
	}

}

.modal-document {

	.agile-table-select-all {
		padding-left: 0;
	}

}
.modal-content-document {
	padding: 2rem;
}

.modal-footer-document,
.modal-header-document {
	border-top: none !important;
	border-bottom: none !important;
}

#table-report {
	.icon-report {
		margin-left: 0.7rem;
	}

	thead {

		> {

			tr {

				>td {
					margin-right: 2rem;
				}

			}

		}

	}

	.btn {
		font-size: 15px !important;
	}

}

.table-edit-admin {

	tbody {

		> {

			tr {

				> {

					td {
						padding-right: 0;
					}

				}

			}

		}

	}

}

.input-moderation {
	border: none;
	padding-right: 7px;
	background: none;
	background-image: none;
	box-shadow: none;
}

.input-moderation::placeholder {
	color: $dark-grey;
	font-size: 14px;
}

.xdsoft_datetimepicker .xdsoft_calendar td,
.xdsoft_datetimepicker .xdsoft_calendar th,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div {
	background: $white !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current {
	background: $light-blue !important;
	color: $dark-grey !important;
	box-shadow: none !important;
}

.moderation-table {

	select {
		padding: 7px;
	}

}

.map-platform {
	position: sticky;
	top: 0;
}

.moderation-table-ooh {

	tbody {

		> {

			tr {

				> {

					td {
						vertical-align: middle;
						padding: 10px;
					}

				}

			}

		}

	}

}

.modal-media {
	padding: 1rem;
}

#create-project {

	.chosen-container {
		border: 1px solid $blue-grey;
		padding: 1.2rem;
		border-radius: 3px;
		.chosen-single{
			padding-left: 0;
		}
	}
	.multiselect.dropdown-toggle.btn{
		border: 1px solid $blue-grey !important;
		padding: 1.2rem;
		border-radius: 3px;
		.multiselect-selected-text{
			float: left;
		}
		.fa-sort{
			color: $blue-grey;
			margin-left: 0;
		}
	}
	.multiselect-container.dropdown-menu{
		width: 100%;
		background: $fog-grey;
		box-shadow: none;
		border: none;
		.checkbox input[type=checkbox]{
			margin-right: 1rem;
		}
	}
	.control-label{
		padding-top: 2rem;
		padding-bottom: 2rem;
		color: #5c5c5c;
		font-size: 15px;
		font-weight: 500;
		box-shadow: none;
		background-image: none;
	}
	.form-group{
		margin: 0;
	}
	#button-group{
		text-align: right;
		margin-top: 2rem;
	}
	textarea{
		border: 1px solid $blue-grey;
		box-shadow: none;
	}
	#date-create-project,
	.menu-project{
		display: flex;
		justify-content: space-between;
		.date{
			padding-bottom: 2rem;
			color: $light-grey;
		}
		#project_dateEnd_year_chosen,
		#project_dateEnd_day_chosen{
			margin-left: 2rem;
			padding: 1rem;
		}
	}
	.date-create-project{
		#project_dateStart_month_chosen{
			padding-left: 0;
			margin-right: 2rem;
			padding: 1rem;
		}
		#project_dateStart_day_chosen{
			margin-right: 2rem;
			padding: 1rem;
		}
	}

}

.fix-center {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

.border-button-order {
	border: 1px solid $blue;
	border-radius: 3px;
	background: transparent;
	padding: 0.5rem;
	color: $blue;
	font-size: 14px;
	font-weight: 400;
}

.btn-group-order {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	padding-left: 0;
	padding-right: 0;
}

.flex-space {
	display: flex;
	justify-content: space-between;
}

::-webkit-scrollbar {
	background-color: $natural-grey;
	border-radius: 3px;
}

::-webkit-scrollbar-thumb {
	background-color: $blue-grey;
	border-radius: 3px;
}

.w-50 {
	width: 50px;
}


.delete-temp {
	cursor: pointer;
}

.delete-temp:hover {
	color: $dark-red;
}

#main-table {

	thead {

		> {

			tr {

				> {

					td {
						font-size: 1.5rem;
						font-weight: 500;
					}

				}

			}

		}

	}

}

.popup-component {
	background: $fog-grey;
	padding: 2rem;
	position: fixed;
	bottom: 9rem;
	right: 6rem;
	box-shadow: 2px 4px 4px $shadow;
	border-radius: 4px;
	opacity: 0;
	z-index: 1;
}

.popup-component.active {
	opacity: 1;
}

#group-operations-popup {
	width: 300px;
}

.button-switch {
	display: flex;
	button {
		background: $white;
		border-radius: 3px;
		padding: 0.5rem 1rem;
		color: $dark-grey;
		flex-basis: 50%;
		white-space: nowrap;
	}

	button.active {
		background: $blue;
		color: $white;
	}

}

.button-save-popup {
	background: $blue;
	border-radius: 3px;
	padding: 0.5rem 1rem;
	color: $white;
}

.modal-dialog {
	z-index: 5000;
	position: absolute;
	display: none;
}

.padding-side-15 {

	.row {
		padding-left: 15px;
		padding-right: 15px;
	}

}

.mediaplans {
	width: 100%;
	td {
		border-bottom: 1px solid $blue-grey;
	}

	tbody {

		td {
			padding: 10px;
			color: $dark-grey;
			padding-left: 0;
		}

	}

	.price {
		color: $blue;
		font-size: 1.4em;
		font-weight: 500;
	}

	&.table-client {
		.face-name {
			font-weight: normal;
		}
	}

	.fact-value {
		flex-flow: column;
	}

	.plan-value {
		font-weight: normal;
		font-size: 12px;
	}
}

table.mediaplans thead td, table.mediaplans thead th, table.mediaplans tfoot td {
	background: white;
	color: $grey;
	font-weight: 500;
	padding: 10px;
	padding-left: 0;
	font-size: 15px;
}

.btn-success {
	background-color: $blue;
	border-color: $blue;
}

.documents-common-sum {
	display: flex;
	justify-content: flex-end;
}


.nav-justified {

	> {

		li {

			> {

				a {
					border-radius: 4px;
					padding-bottom: 14px;
				}

			}

		}

	}

}

// Budget

.filter-budget{
	strong{
		font-weight: 400;
		color: $blue;
		margin-left: 2rem;
	}
}
#budget-table{
	td{
		padding: 10px;
		padding-left: 0;
	}
}
.budget-footer{
	margin-top: 3rem;
	input{
		width: 5rem;
		height: 3rem;
		border: none;
		border-bottom: 1px solid $blue-grey;
		margin-left: 2rem;
	}
	textarea{
		width: 30rem;
		border: none;
		border-bottom: 1px solid $blue-grey;
	}
}
.select-type {
	color: $light-grey;
	border: 1px solid $blue-grey;
	padding: 1.2rem;
	width: 100%;
}

.select-type-mediaplan {
	color: $light-grey;
	border: 1px solid $blue-grey;
}

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
	padding: 15px;
	border-radius: 0;
	background-color: $blue;
	background-image: none;
	box-shadow: none;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.ymaps-2-1-75-controls__control_toolbar{
	margin-left: 6rem !important;
	margin-top: 2.5rem;
}
#table-report{
	.pseudo-icon:before{
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
}
.progress{
	background-color: $fog-grey;
	background-image: none;
	background-repeat: none;
	.progress-bar{
		color: $light-grey;
	}
}
.project-table{
	.fa-pencil{
		color: $blue;
		margin-right: .5em;
	}
	.fa-trash-alt{
		color: $red;
	}
}
.input-line{
	border: none;
	border-bottom: 1px solid $blue-grey;
	padding: 8px;
	&::placeholder{
		padding-left: 0;
		font-size: 14px;
	}
}
#create-project{
	margin: 0 auto;
	width: 800px;
	#create-project-table{
		width: 100%;
		tr{
			width: 100%;
			td{
				padding-bottom: 2rem;
				color: #868686;
				font-size: 15px;
				font-weight: 500;
				width: 100%;
			}
		}
		input{
			width: 100%;
			padding: 1.2rem;
			border: 1px solid $blue-grey;
			border-radius: 3px;
		}

		.has-error {
			.error-text {
				color: $red;
				font-size: 12px;
			}

			input {
				border-color: $red;
			}
		}
	}
}
input:disabled{
	opacity: 0.53;
	background-color: transparent;
}
button.disabled {
	opacity: 0.53;
}
.project-mediaplan{
	tr{
		th{
			font-weight: 500;
			color: $grey;
			font-size: 15px;
		}
	}
	.fa-trash-alt{
		color: $red;
	}
}
tbody{
	.dropdown-toggler{
		padding-left: 0;
	}
}
.subtitle{
	font-size: 2.4rem;
	color: $blue;
	font-weight: 500;
	padding-bottom: 1rem;
}

.container-button-advert{
	display: flex;
	justify-content: space-between;
	align-items: center;
	.note{
		font-size: 1.6rem;
		color: $grey;
	}
	button{
		background-color: transparent;
		.fa-plus{
			color:$blue;
		}
		&:last-child{
			padding-right: 0;
		}
		.title-button{
			margin-left: 1rem;
			color: $blue;
		}
	}
}
.w-40{
	width: 40%;
}
.vertical-center{
	vertical-align: middle !important;
	display: flex;
	margin-left: 10px;

	button{
		padding-right: 0;
		background-color: transparent;
	}
}
.input-wrap{
	position: relative;

	span{
		/*position: absolute;*/
		left: 0;
		top: 3rem;
		color: $red;
		font-weight: 400;
		font-size: 12px;
		z-index: 2;
		white-space: nowrap;
	}

	.face-budgets-sum {
		color: inherit;
		font-size: inherit;

		&.error {
			color: $red;
		}
	}

}
.w-100{
	width: 100%;
}
.w-25{
	width: 25%;
}
.w-20{
	width: 20%;
}
.w-2{
	width: 2%;
}

.w-50{
	width: 50%;
}
.w-15{
	width: 15%;
}
.redesign-table{
	&.table-client{
		thead{
			th{
				border-bottom: none !important;
			}
		}
	}
}

.accordion__button, .accordion__button:hover {
	background-color: #fff;
}

.mediaplan-section-title {
	margin-top: 20px;
	font-size: 24px;
	font-weight: 500;
}

.mediaplan-fact-button {
	margin-right: 50px;
}
.project-channel-item {
	height: 20px;
	white-space: nowrap;
}

.money, .text-right {
	text-align: right;
}

.flex-right {
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.flex-end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.flex-left {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.mediaplan-page {
	.edit-client {
		padding-left: 1rem;
		padding-right: 4rem;
		display: block;
		overflow-x: scroll;

		.table-header {
			td {
				background: #f5f5f5;
			}
		}
	}

	.loader {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.loader.hidden {
		display: none;
	}

	.mediaplan-tables {
		display: flex;
		position: relative;

		input:not([type='checkbox']) {
			max-width: 130px;
			min-width: 130px;
		}

		td.error {
			color: $red;
		}

		&.periods {
			display: block;
		}
	}

	.advert-buttons {
		margin-top: 20px;
	}

	.plan-fact-values {
		.value {
			margin-right: 10px;
		}

		input {
			min-width: 60px;
			padding: 2px;
		}
	}

	.money {
		text-align: right;

		.plan-fact-values {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	.edit-client .table-client td {
		padding: 10px;
	}

	.edit-client .table-client input:disabled {
		//max-width: unset;
	}

	.advert-name, .advert-name-link {
		margin-right: 30px;
	}

	.advert-name {
		width: 50%;
	}

	.advert-detail-buttons {
		display: flex;
	}

	.correction-button {
		cursor: pointer;
		margin-left: 10px;
	}

	.services-table {
		input {
			border: 1px solid #C5CFDC;
			padding: 1.2rem;
			border-radius: 3px;
		}

		.react-select-container {
			min-width: 300px;
		}
	}

	.fa-check.check-plan {
		color: $red;
	}

	.fa-check.check-plan.has-plan {
		color: $light-green;
	}

	.header, .adverts-for-merge {
		display: flex;
	}

	.header {
		align-items: center;
		margin-bottom: 20px;
	}

	.adverts-for-merge a {
		cursor: pointer;
		margin-left: 40px;
	}

	.selected-advert-name {
		font-weight: bold;
	}

	.row {
		padding-bottom: 50px;
	}
}

.plan-table {
	.edit-client .table-client td.select-paste-column {
		padding-left: 130px;
	}
}

.fact-table {
	.detail-cell {
		flex-flow: column;
		align-items: flex-end;

		.formatted-number {
			font-size: 13px;
			color: #444;
			padding-right: 16px;
		}
	}

	.face-buttons {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

    .face-checking-title {
        font-size: 13px;
        font-weight: 400;
        color: $salamander;
    }

	&__buttons {
		&-send {
			background: none;
			font-size: 20px;
			height: 36px;
			margin-left: 20px;
		}

		&-history {
			color: $red;
			font-size: 20px;
		}
	}
}

.face-checked-title {
	font-size: 13px;
	font-weight: 400;
	color: $lime;
}

.face-for-check-title {
	font-size: 13px;
	font-weight: 400;
}

div.goal{
    border-bottom: 1px solid $blue-grey;

    .goal-detail {
        td.error {
            color: $red;
        }
    }
}

.goal-detail-approved--text {
	margin-left: 10px;
}

.edit-client .table-client td {
	border-bottom: 1px solid #C5CFDC;
}

.pagination li:not(.active), .pagination li:not(.disable){
	cursor: pointer;
}

.adverts-menu-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.advertising-table.table-edit-client {
	width: 100%;
	margin-bottom: 40px;

	.input-line {
		border: none;
		border-bottom: 1px solid #C5CFDC;
		padding: 8px;
	}
}

.advertising-table {
	&__buttons {
		display: flex;
		align-items: center;

		&-upload, &-send {
			height: 64px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			padding-top: 20px;
			font-size: 20px;
		}

		&-upload {
			margin-left: 10px;
		}

		&-send {
			background: none;
		}
	}
}

.advertising-main-table.table-edit-client {
	width: 100%;

	.react-datepicker-wrapper {
		width: unset;
	}

	.react-datepicker__tab-loop {
		display: inline-block;
	}

	.input-line {
		border: none;
		border-bottom: 1px solid #C5CFDC;
		padding: 8px;
	}
}

.mediaplan-tables {
	.sum-values-row {
		background: #f5f5f5;

		td {

			div {
				color: #6d6d6d;
				font-weight: 500;
			}
		}
	}
}

.name-page {
	.title-text {
		color: #6d6d6d;
	}
}

.accordion__button:before {
	position: absolute;
	margin-top: 4px;
}

.advert-name-wrapper {
	display: flex;
	margin-left: 20px;
	justify-content: space-between;
	user-select: unset;
}

.services-name-wrapper {
	margin-left: 20px;
}

.advert-merge {
	display: inline-block;
	width: 30px;
	text-align: center;
}

.advert-merge.selected {
	color: $red
}

.sort {
	cursor: pointer;
	user-select: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.sort i{
	margin-left: 10px;
}

.loader-background {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #fff;
	opacity: 0.6;
	z-index: 3;
}

.loader {
	position: fixed;
	top: 50%;
	left: 56%;
	z-index: 4;

	&.face-form-loader {
		position: absolute;
	}
}

.pointer div{
	cursor: pointer;
}

.menu-filter {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;

	.filter-item {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		margin-right: 20px;

		&.error input {
			border-color: $red;
		}

		&.error .react-select-container {
			border-bottom: 1px solid red;
		}

		&.error .range-date-wrapper {
			border-bottom: 1px solid red;
		}

		&.error .react-datepicker-wrapper {
			border-bottom: 1px solid red;
		}

		.react-datepicker-wrapper {
			.range-button {
				height: 38px;
				background: #fff;
			}
		}
	}

	.radio-filter-block {
		display: flex;
		justify-content: center;
		flex-flow: column;
		align-items: flex-start;
		margin-bottom: 20px;
	}
}

.advertising-main-table {

	td.without-budget input{
		border-color: $red;
		opacity: 0.4;
	}

	td.without-budget div{
		color: $red;
	}

	.status-filter.without-plan, td.without-budget input{
		color: $red;
	}

	td.without-budget input:focus{
		border-color: $blue-grey;
		opacity: unset;
		color: inherit;
	}

	.radio-filter-block {
		padding: 10px;
		border: 1px solid #ddd;
		width: 300px;
		margin-right: 20px;
		display: inline-block;
	}

	.status-filters {
		display: flex;
	}

	.status-filter {
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	td.advert-date {
		white-space: nowrap;
		opacity: 0.7;
	}

	td.current, .status-filter.current {
		color: $red;
	}

	td.passed, .status-filter.passed {
		color: green;
	}

	td.upcoming, .status-filter.upcoming {
		color: $blue;
	}
}

.has-error {
	.error-text {
		color: $red;
		font-size: 12px;
		display: block;
	}

	input {
		border-color: $red;
	}

	.react-select__control {
		border-color: $red;
	}
}

.error-colored {
	color: $red;
}

.success-colored {
	color: $lime;
}

.edit-client {
	td.has-error {
		color: $red;
		min-width: 250px;

		.formatted-number {
			color: $red;
		}
	}

	input.has-error, .select-pseudo-input.has-error {
		border-color: $red;
	}

	.input-wrap span {
		display: block;
	}

	.error .error-title {
		font-size: 12px;
		display: block;
		text-align: left;
	}

	.flex-right .input-wrap {
		text-align: right;
	}

	.used-before {
		font-weight: 400;
		font-size: 12px;
	}
}

.boundary-error {
	&.main-error {
		position: fixed;
		left: 37%;
		top: 43%;
	}

	&.mediaplan-error {
		padding: 50px;
	}
}

.advertising-financial .advert-buttons {
	display: flex;
}

.status-filter input {
	margin-right: 10px;
	margin-top: unset;
}

.advertising-financial {
	.advert-approved {
		margin-left: 10px;
	}
}

.back-button {
	margin-left: 20px;
}

.nowrap {
	white-space: nowrap;
}

.fa-trash-alt {
	color: red;
}

.company-edit, .detail-planning, .registry-edit-form, .menu-filter, .edit-client {
	.react-select-container .react-select__multi-value__remove{
		svg {
			display: block;
		}
	}

	.react-select__clear-indicator {
		display: none;
	}
}

.flex-align-center {
	align-items: center;
}

.period-faces {
	&__wrapper {
		width: 100%;
	}

	&__menu-buttons {
		display: flex;
		align-items: center;
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;

		.period-face {
			width: 350px;
			margin-right: 20px;
			margin-bottom: 20px;
			border: 1px solid #C5CFDC;
			position: relative;
			min-height: 150px;

			&:hover {
				background: #f8f8f8;

				.period-face__button {
					display: flex;
				}

				.period-face__rows {
					opacity: 0.6;
				}

				.period-face__title {
					color: $blue;
					cursor: pointer;
				}
			}

			&__header {
				display: flex;
				justify-content: flex-end;
				min-height: 22px;
				padding-top: 5px;
			}

			&__body {
				padding: 0 25px 25px;
			}

			&__rows {
				display: flex;
				flex-flow: column;
				align-items: self-start;
			}

			&__row {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}

			&__footer {
				margin-top: 20px;
			}

			&__title {
				margin-top: 5px;
			}
		}
	}

	.add-link {
		cursor: pointer;
	}

	&.advert-plan-faces button {
		background: transparent;
	}
}

.advert-plan-faces {
	padding-bottom: 50px;
}

.period-face__button {
	background: #f8f8f8;
	color: #333;
	font-size: 14px;
	margin-left: 10px;
	display: none;

	&.add {
		position: absolute;
		top: 32%;
		left: 38%;
		font-size: 70px;
		background: unset;
		color: $blue;
		cursor: pointer;
		opacity: 0.8;
	}
}

.delete-face-button {
	i {
		font-weight: normal;
	}
}

.face-add-commission {
	cursor: pointer;
	display: flex;
	border: 1px solid $blue;
	color: $blue;
	border-radius: 3px;
	width: 16px;
}

.delete-face-buttons {
	display: flex;
	justify-content: flex-end;

	span {
		margin: 0 5px;
		cursor: pointer;
	}
}

.detail-name {
	text-decoration: none;
	cursor: pointer;
	word-break: break-all
}

.detail-name:hover {
	text-decoration: none;
}

.popup-form {
	&__background {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		opacity: 0.5;
		background: #000;
		cursor: pointer;
		z-index: 3;
	}

	&__wrapper {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		width: 40%;
		background: #fff;
		overflow-x: scroll;
		z-index: 4;
		min-width: 500px;
	}

	&__body {
		padding: 0 30px 30px;
	}

	&__header {
		height: 30px;
		display: flex;
		justify-content: flex-end;
		padding: 5px 5px 0 0;

		button {
			background: #fff;
		}
	}

	&__row {
		margin-bottom: 20px;

		input {
			width: 100%;
			border: 1px solid #C5CFDC;
			height: 30px;
			padding: 15px;
		}


		input.error {
			border-color: $red;
		}

		input[type="checkbox"] {
			width: unset;
			border: none;
		}

		&.unit-fields {
			display: flex;
			justify-content: flex-start;

			.unit-field {
				&:last-child {
					margin-left: 30px;
				}

				.unit-cost {
					height: 50px;
					text-align: right;
					padding-right: 10px;
					width: 120px;
				}
			}
		}

		.react-select__multi-value__remove svg, .react-select__dropdown-indicator svg {
			display: block;
		}

		.react-select__dropdown-indicator {
			padding: 0;
			padding-right: 8px;
		}

		.react-select-container .react-select__control::after {
			content: '';
		}
	}

	&__row-comment {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.input-wrap {
			width: 90%;
			margin-right: 20px;
		}

		a {
			cursor: pointer;
		}
	}

	&__buttons {
		margin-top: 20px;
	}

	&__readonly {
		padding: 15px;
		border: 1px solid #eee;
		margin-bottom: 10px;
	}

	.face-code {
		&__input {
			height: 44px;
		}

		&__error {
			color: red;
		}
	}
}

.registry-edit {
	.documents-list, .faces-list {
		margin-top: 50px;
	}

	.documents-list {
		.edit-document {
			cursor: pointer;
		}
	}
}

.advert-props.detail-buttons {
	display: flex;
}

.sum-values {
	display: flex;
	justify-content: flex-end;
}

.invoice-list {
	.add-invoice-button:disabled {
		opacity: 0.5;
	}
}

.budget-code-input {
	width: 100px;
}

.corrections-save-button {
	margin: 10px;
}

.decimal-input {
	input {
		text-align: right;
	}
}

#edit-order {
	.decimal-input {
		&.has-error {
			input {
				border-color: red;
			}
		}
	}

	.react-datepicker__input-container {
		input.has-error {
			border-color: red;
		}
	}
}

.formatted-number {
	font-size: 14px;
	font-weight: 400;
	text-align: right;
	letter-spacing: normal;
	line-height: 20px;
	color: #000;
	padding-right: 10px;

	.input-wrap span {
		color: inherit;
		font-weight: 500;
	}
}

.table-paste-button {
	margin: 0 20px;
	cursor: pointer;
}

.select-paste-column {
	text-align: center;
}

.paste-textarea {
	width: 100%;
}

.budget-cell, .detail-cell {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.edit-client .table-client .detail-cell {
	input {
		border: none;
		padding: 5px 15px;
		background-color: #f5f5f5;

		&:focus-visible {
			outline: none;
		}
	}

	input[type="checkbox"] {
		padding: 0;
		background: none;
		min-width: unset;
	}

	input[type="checkbox"]:after {
		border-color: #999999;
	}

	input[type="checkbox"]:disabled:after {
		border-color: #ddd;
	}
}

.paste-fact-row-wrapper {
	display: flex;
	justify-content: space-between;
}

.get-xls-button {
	margin-left: 10px;
	margin-right: 10px;
	padding: 1.2rem 2rem;
	background-color: transparent;
	border: 1px solid $blue;
	border-radius: 4px;
	color: $blue;
	font-size: 14px;
	white-space: nowrap;

	&:focus {
		outline: none;
	}

	.loader {
		position: static;
		display: inline-block;
		margin: 0 10px;
	}

	&.active {
		background-color: $lime;
		color: #fff;
	}
}

.advert-parts-wrapper {
	margin-top: 40px;

	.subtitle {
		font-size: 1.8rem;
	}
}

.fill-budget {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 14px;
	cursor: pointer;
 }

.plan-detail-row {
	&.in-edit {
		background: #f8f8f8;
	}
}

.reset-detail-button{
	cursor: pointer;
}

.xls-upload-buttons {
	display: flex;
	align-items: center;

	.loader {
		position: static;
		margin-left: 50px;
		font-size: 20px;
	}
}

.upload-data-wrapper {
	margin-top: 40px;

	&__message {
		font-size: 16px;
		color: $lime;
		white-space: pre-line;
	}
}

.advertisings-list {
	&__title {
		font-size: 16px;
		margin-top: 20px;
		margin-bottom: 5px;
	}

	&__list {
		padding-left: 10px;
	}
}

.advertising-matching-errors {
	&__title {
		font-size: 16px;
		margin-top: 20px;
		margin-bottom: 5px;
	}

	&__list {
		width: 50%;
		padding-left: 10px;
	}

	&__column {
		padding: 0 10px;
		margin-bottom: 10px;
	}

	&__column-title {
		display: flex;
		justify-content: center;
		background: rgba(221, 221, 221, 0.3);
		padding: 5px;
		border-bottom: 1px solid #ddd;
	}

	&__column-row {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
	}

	&__column-row:first-child {
		font-size: 14px;
	}

	&__column-value {
		margin-right: 20px;
	}

	&__column-number {
		text-align: right;
		max-width: 200px;
	}

	&__column-row-header {
		justify-content: space-around;
		font-size: 14px;
		background: rgba(221, 221, 221, 0.3);
		padding: 5px;
	}
}

.advertising-writing-errors {
	&__title {
		font-size: 16px;
		margin-top: 20px;
		margin-bottom: 5px;
	}

	&__list {
		padding-left: 10px;
	}

	&__advert {
		margin-bottom: 10px;

		&-title {
			padding: 5px;
			background: rgba(221, 221, 221, 0.5);
		}

		&-error {
			font-size: 12px;
			padding: 5px;
			background: rgba(221, 221, 221, 0.3);
		}
	}
}

.upload-xls-errors {
	color: $red;
	margin-top: 20px;

	.upload-xls-errors__title {
		font-size: 18px;
		margin-bottom: 10px;
	}
}

.upload-xls-success {
	margin-top: 30px;
}

.ratios {
	.ratio-sum {
		padding: 10px;
	}

	.ratio-sum--field {
		margin-right: 30px;
	}

	.ratio-toggle {
		cursor: pointer;
	}

	.ratios-wrapper {
		padding-left: 10px;
	}

	.ratios-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;

		&__name {
			padding-left: 20px;
		}

		input {
			padding-right: 10px;
			width: 80px;
			height: 30px;
		}

		&__percents {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

.face-unit {
	&.wrapper {
		display: flex;
	}

	&__item {
		cursor: alias;

		.formatted-number {
			color: unset;

			.input-wrap {
				span {
					font-size: 10px;
					font-weight: 500;
				}
			}
		}
	}
}

.registry-reference-fields {
	display: flex;
	justify-content: space-between;

	&>div {
		width: 40%;
	}
}

.range-date-wrapper {
	.period {
		padding: 5px;
		font-size: 10px;

		&.active {
			background: #f8f8f8;
		}

		&:last-child {
			margin-left: 6px;
		}
	}

	.range-button {
		background: none;
		padding: 8px;
	}
}

.source-form__wrapper {
	.source-form__content {
		display: flex;

		.source-form__button {
			margin-left: 10px;
		}
	}
}

.save-button-wrapper {
	justify-content: flex-end;
	padding-top: 20px;
	display: flex;
}

.btn-with-loader {
	display: flex;

	.loader {
		position: static;
		margin-left: 10px;
	}
}

.loading {
	opacity: 0.4;
}

.radio-block {
	display: flex;
	flex-wrap: wrap;
}

.filter-group-wrapper {
	display: inline-block;
	margin-right: 20px;
	margin-bottom: 20px;

	.react-select__menu {
		z-index: 3;
	}

	.react-select__multi-value__label {
		padding: 2px;
		max-width: 180px;
		font-size: 10px;
	}

	.filter-group {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;

		.react-select__value-container {
			box-shadow: 0px 1px 2px #c5cfdc, 0px 0px 0px 1px #c5cfdc;
		}

		.react-select__control {
			min-height: unset;
		}

		.filter-item {
			/*margin-right: 20px;*/
			cursor: pointer;

			input {
				display: none;
				margin-right: 5px;
				cursor: pointer;
			}

			label {
				padding: 5px 10px;
				border: 1px solid #C5CFDC;
				cursor: pointer;

				&.active {
					border: 1px solid $blue;
					background: $blue;
					color: white;
				}

				span {
					white-space: nowrap;
				}
			};

			&:first-child {
				label {
					border-top-left-radius: 10px;
					border-bottom-left-radius: 10px;
				}
			}

			&:last-child {
				label {
					border-top-right-radius: 10px;
					border-bottom-right-radius: 10px;
				}
			}

			&:not(:last-child) {
				label {
					border-right: none;
				}
			}
		}

		&__title {
			color: $blue;
			font-size: 12px;
			margin-bottom: 5px;
		}
	}

	&__props {
		width: 80%;
	}
}

.affinities-wrapper {
	margin-top: 20px;
}

.affinity-wrapper {
	display: flex;
	margin-bottom: 20px;

	.affinity-item {
		display: flex;

		.react-datepicker-wrapper {
			width: 30%;
			margin-right: 20px;
		}
	}
}

.quality-wrapper {
	display: flex;
	border: 1px solid #c5cfdc;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;

	.quality-item {
		width: 100%;

		&__row {
			display: flex;

			&.spaced {
				justify-content: space-between;
				align-items: flex-start;
			}

			&-field {
				margin-right: 20px;
				width: 50%;

				.title {
					font-size: 10px;
				};

				&.comment {
					width: 100%;
				}
			}

			.react-datepicker-wrapper {
				width: unset;
			}
		}

		&__row-fields {
			display: flex;
		}
	}

	&.durations {
		justify-content: space-between;
		align-items: flex-start;

		.quality-item__row:not(.spaced) {
			flex-flow: column;
		}

		.duration-error {
			margin-left: 20px;
			color: $red;
			font-size: 12px;
			display: flex;
			align-items: center;
		}
	}
}

.datasources-wrapper {
	margin-top: 20px;
}

.datasource-wrapper {
	display: flex;
	margin-bottom: 20px;

	.datasource-item {
		display: flex;
		width: 100%;

		.react-select-container {
			margin-right: 10px;
			width: 60%;
		}

		.react-select-container:last-child {
			width: 40%;
		}
	}

	.datasource-delete {
		background: none;
	}
}


.card {
	background-color: rgb(255, 255, 255);
	color: rgb(23, 43, 77);
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 16px;
	box-shadow: 0px 1px 2px #c5cfdc, 0px 0px 0px 1px #c5cfdc;
	background-image: none;
	padding: 8px 15px;
}

.back-buttons {
	display: flex;
	margin: 20px 0;
}

.arrow-link {
	text-decoration: none;
	font-weight: 500;
	font-size: 14px;
	min-width: 64px;
	margin-right: 40px;

	&__text {
		display: flex;
		align-content: center;
	}

	&__icon {
		display: flex;

		svg {
			width: 20px;
			height: 20px;
		}
	}
}

.detail-footer {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
	border-top: 1px solid #c5cfdc;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	color: #6d6d6d;
	text-align: center;
	padding: 10px 50px 10px 100px;
}

.btn-with-loader {
	&.button {
		display: inline-flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: center;
		justify-content: center;
		position: relative;
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
		outline: 0px;
		border: 0px;
		margin: 0px;
		margin-right: 5px;
		cursor: pointer;
		user-select: none;
		vertical-align: middle;
		appearance: none;
		text-decoration: none;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
		font-weight: 600;
		font-size: 14px;
		line-height: 1.75;
		min-width: 64px;
		padding: 6px 30px;
		border-radius: 20px;
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		color: rgb(255, 255, 255);
		background-color: $blue;
		text-transform: none;

		&:hover {
			background-color: $dark-blue;
		}

		&.load {
			background-color: $dark-blue;
		}
	}
}

.icon-button-with-loader {
	position: relative;
	width: 25px;
	height: 25px;

	&.loading {
		border: 1px solid $blue;
	}

	.fa-save {
		font-size: 22px;
		color: $blue;
	}
}

.check-buttons {
	display: flex;
	align-items: center;
}

.row-check-buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.correction-button {
		height: 20px;
		margin-left: 10px;

		.fa-save {
			font-size: 20px;
		}
	}
}

.loader-backdrop {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
}

.tabs {
	display: flex;
	border-bottom: 1px solid #dedede;

	.tab {
		padding: 15px;
		cursor: pointer;
		border-right: 1px solid #dedede;

		&.active {
			background: #dedede;
		}
	}
}

.tabs-content {
	padding: 50px 10px;
}

.plan-general-info {
	margin-bottom: 30px;

	tr {
		td:last-child {
			color: #6d6d6d;
			font-weight: 500;
		}
	}

	td {
		padding: 5px 20px 0 0;
	}
}



.plan-data {
	.decimal-input {
		input {
			opacity: unset;
		}
	}

	.button {
		display: inline-flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: center;
		justify-content: center;
		position: relative;
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
		outline: 0px;
		border: 0px;
		margin: 0px;
		cursor: pointer;
		user-select: none;
		vertical-align: middle;
		appearance: none;
		text-decoration: none;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
		font-weight: 600;
		font-size: 14px;
		line-height: 1.75;
		min-width: 64px;
		padding: 6px 30px;
		border-radius: 20px;
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		color: rgb(255, 255, 255);
		background-color: $blue;
		text-transform: none;

		&:hover {
			background-color: $dark-blue;
		}

		&.load {
			background-color: $dark-blue;
		}
	}

	&.mediaplan-page .row {
		padding-bottom: 20px;
	}

	.mediaplans, .table {
		width: unset;
	}
}

.faces-total {
	.input-wrap {
		input {
			max-width: 150px;
			width: unset;

			&:disabled {
				opacity: unset;
			}
		}
	}

	.edit-client .table-client {
		td {
			font-weight: 400;
			color: #444;
		}

		.table-header, .sum-values-row {
			td {
				font-weight: 500;
				color: #6d6d6d;
			}
		}
	}

	.mediaplan-tables {
		input[type=checkbox] {
			min-width: unset;
		}
	}
}

.modal-window {
	padding: 20px;

	&__header {
		text-align: center;
		font-size: 20px;
		color: #2c7bb2;
		font-weight: 500;
		margin-bottom: 20px;
	}

	.close {
		position: absolute;
		right: 15px;
		top: 15px;
	}
}

.modal-window.popup-face-check {
	.name-page-client {
		text-align: center;
	}

	.row-item {
		margin-bottom: 30px;

		textarea {
			width: 100%;
		}
	}

	.row-item-buttons {
		text-align: center;
	}
}

.radio-group {
	margin-right: 20px;

	label {
		margin-bottom: 0;
	}

	&.__title {
		margin-bottom: 5px;
		display: block;
	}
}

.checkbox-group {
	.checkbox-item {
		input {
			margin-right: 10px;
		}

		.title {
			cursor: pointer;
		}
	}
}

.react-datepicker__input-container {
	input:read-only {
		opacity: 0.2;
	}

	input {
		height: 38px;
	}
}

.contractors-name {
	cursor: pointer;
}

.contractor-check-rows {
	width: 30%;

	.check-row {
		display: flex;
		justify-content: space-between;
	}
}

.contractor-buttons {
	display: flex;

	a {
		cursor: pointer;
	}

	:not(:last-child) {
		margin-right: 10px;
	}
}

.row-with-diff {
	background-color: #ffdede;
}

.props-select {
	max-width: 65%;
	min-width: 400px;
}

.reconciliation-list {
	.is-negative input.margin {
		color: red;
	}
}

.with-bottom-text {
	.detail-cell {
		flex-flow: column;
		min-width: 130px;

		span.cell-bottom {
			font-size: 10px;
			text-align: left;
			white-space: nowrap;
		}

		span.cell-bottom.checked {
			color: $lime;
		}

		span.cell-bottom.non-checked {
			color: $red;
		}
	}
}

.check-comment {
	display: flex;
	margin-bottom: 10px;

	i {
		cursor: pointer;
	}

	.fa-edit {
		margin-right: 5px;
	}
}

.redesign-table tbody td:first-child.nested-item {
	padding-left: 15px;
}

.platforms-select .react-select-container {
	width: 210px;
}

.financial-report {
	.formatted-number .input-wrap span {
		font-weight: 400;
	}
}

input[type=checkbox].financial-report__filter-checkbox {
	margin-right: 10px;
}

.face-info-table {
	tr td {
		text-align: left;
	}
}

.period-face-title {
	display: flex;
	min-width: 150px;
	word-break: break-word;

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__block {
		width: 100%;
		display: flex;
		justify-content: space-between;

		.correction-button {
			cursor: pointer;
		}
	}

	span {
		margin-right: 5px;
		cursor: pointer;
	}
}

.edit-invoice {
	.table-client {
		width: 50%;
		margin: auto;
	}

	@media (max-width: 1023px) {
		.table-client {
			width: 100%;
		}
	}
}

.history-popup {
	width: 80%;
	padding: 16px;
}

.footer-link-button {
	margin-left: 20px;
}

.pull-add-button {
	white-space: nowrap;
}

.table-index {
	margin-right: 10px;
}

.form-accordion {
	&__wrapper {
		margin-bottom: 20px;
	}

	&__header {
		display: flex;
	}

	&__body {
		padding-left: 20px;
	}

	&__toggle {
		cursor: pointer;
		margin-left: 20px;
	}
}

.popup-form__row input.factor-value {
	height: 50px;
}

.month-fact-icon {
	cursor: pointer;

	&.success {
		color: $lime;
	}

	&.warning {
		color: $salamander;
	}

	&.error, &.danger {
		color: $red;
	}

	&.danger {
		opacity: 0.4;
		cursor: unset;
	}
}

.month-fact-table {
	&__row-sub-title {
		font-size: 12px;
	}

	.row-face > td {
		font-weight: 400;
	}
}

.month-fact-buttons {
	min-width: 20px;

	.loader {
		position: static;

		i {
			font-size: 12px;
			font-weight: 300;
		}
	}

	div {
		cursor: pointer;
	}
}

.edit-client .table-client {
	.ant-table-cell {
		padding-right: 16px;
	}
}

.ant-table-cell {
	color: #6d6d6d;

	.formatted-number {
		color: #6d6d6d;
	}

	.period-face-title__wrapper {
		font-weight: 500;

		.buttons {
			display: flex;
		}

		.button {
			margin-left: 10px;
			cursor: pointer;
		}
	}

	.decimal-input input {
		border: 1px solid #898a8c;
		border-radius: 4px;
		padding: 5px;
	}

	.detail-cell {
		justify-content: center;

		input {
			max-width: 140px;
		}
	}
}

.ant-table-thead > tr > th {
	color: #6d6d6d;
}

.ant-table-summary {
	font-weight: 500;

	.ant-table-cell-fix-left-last {
		padding-left: 64px;
	}
}

@keyframes faceAdd {
	from {
		color: #e8ecef;
	}

	to {
		color: #428bca;
	}
}

.plan-data-page {
	.plan-period {
		&__row-buttons {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 50px;

			&-save {
				background: none;
			}
		}

		.table-paste-button {
			margin: 0;
		}

		.decimal-input {
			input {
				width: 120px;
			}
		}

		&__row-buttons{
			&--delete {
				cursor: pointer;
			}
		}

		.ant-table-thead > tr > th,
		.ant-table-tbody > tr > td,
		.ant-table tfoot > tr > th,
		.ant-table tfoot > tr > td {
			padding: 2px 5px;
		}

		.ant-table-thead > tr > th, {
			font-size: 12px;
		}

		.budget-cell {
			justify-content: center;
		}

		.formatted-number {
			text-align: center;
		}

		.new-row .detail-name {
			animation-duration: 0.8s;
			animation-name: faceAdd;
			animation-iteration-count: 2;
		}
	}

	.filter-items {
		display: flex;
		flex-wrap: wrap;
		align-items: start;
	}

	.top-menu {
		margin: 20px 0;

		.title {
			display: flex;
			flex-wrap: wrap;
		}
	}
}



.mediaplan-tables.face-selected {
	.ant-table-summary  {
		position: static !important;
	}

	.ant-table-thead > tr > th, .ant-table tfoot > tr > td, .ant-table-tbody > tr > td {
		position: static !important;
	}
}

.plan-period-dates {
	.period-item {
		cursor: pointer;

		&.selected {

		}
	}

	.date-filter-selected {
		display: flex;

		&__value {
			margin-right: 10px;
		}
	}
}

.date-filter-popup {
	padding: 15px;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 2px;
	outline: none;
	box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;

	.ant-btn {
		width: 100px;
	}
}

.contractor-checks {
	.detail-cell {
		flex-flow: column;
		position: relative;
	}

	&__rows-with-text {
		position: absolute;
		left: 0;
		bottom: -5px;
		width: 2003px;
		text-align: left;
		font-size: 10px;
		color: $red;
	}

	.bottom-text {
		.detail-cell {
			padding-bottom: 15px;
		}
	}

	&__header {
		display: flex;
		margin-top: 20px;
	}

	&__comment {
		display: flex;
		flex-flow: column;

		label {
			font-size: 12px;
			color: $blue;
		}

		input {
			min-width: 400px;
			padding: 10px;
		}
	}
}

.rim-block {
	padding: 15px;

	label {
		margin-top: 20px;

		input {
			padding: 0 15px 0 0;
		}
	}
}

.react-select__menu {
	.react-select__option--is-selected {
		background-color: #fff;
		color: $blue;

		&:active {
			background-color: #fff;
		}
	}

	.react-select__option {
		cursor: pointer;

		label {
			cursor: pointer;
		}
	}
}

.select-pseudo-input {
	.react-select__menu {
		.react-select__option {
			&.react-select__option--is-selected {
				background-color: #fff;
			}
		}
	}
}

.closing-performance {
	.filter-item {
		z-index: 3;
	}

	.detail-cell {
		.select {
			width: 100%;
		}

		.text-non-editable {
			font-size: 11px;
		}

		.input-wrap {
			width: 100%;
		}

		input.advert-field {
			max-width: unset;
		}
	}

	.edit-client {
		padding: 0;
	}

	.advert-all-sum {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__title {
			font-size: 11px;
			line-height: 10px;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		font-size: 14px;

		&-left {
			display: flex;
		}

		.banner-price {
			font-size: 13px;
			margin-bottom: 20px;
		}

		.filter-item {
			margin-bottom: 0;
		}
	}

	.copy-budget {
		cursor: pointer;
	}

	.upload-link {
		margin-top: 6px;

		.fa-upload {
			font-size: 24px;
		}
	}
}

@media (max-width: 1200px) {
	.mediaplan-page .mediaplan-tables {
		flex-flow: column;
	}
}
.Toastify__toast-body {
	white-space: pre-line;
}

.parts-select {
	&__row {
		display: flex;
	}
}

.financial-month-header {
	display: flex;
	justify-content: space-between;

	i {
		font-size: 24px;
	}
}

.delete-button {
	cursor: pointer;
}