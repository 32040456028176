input[type=checkbox] {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	margin-top: 0!important;
}

input[type=checkbox],
input[type=checkbox]:focus {
	outline: none !important;
	box-shadow: none !important;
}

input[type=checkbox]:after {
	padding: 3px;
	top: 1px;
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: normal;
	font-size: 1.1rem;
	content: "\f00c";
	display: block;
	border: solid 1px $pearl;
	border-radius: 3px;
	color: transparent;
	transition: color .1s ease-in-out, border-color .1s ease-in-out;
}

input[type=checkbox]:checked:after {
	color: $blue;
	border-color: $blue;
	outline: none;
}
.input-sm {
	border: none;
	width: 90px;
	color: $dark-grey;
	padding: 0;
	font-size: 14px !important;
}

.input-sm::placeholder {
	color: $dark-grey;
}



.input-budget {
	border: none;
	border-bottom: 1px solid $blue-grey;
	margin-left: 2rem;
	box-shadow: none;
}
.input-with-button {

	> {

		input {
			box-sizing: border-box;
			width: 4.5rem;
			text-align: center;
			border: solid 1px $blue;
			color: $blue;
			border-radius: 3px;
			height: 3.2rem;
			font-weight: 400;
		}

		button {
			height: 100%;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

	}

}
.daterangepicker {
	box-shadow: 0px 2px 6px $shadow;
	th.month {
		width: auto;
		font-size: 1.5rem;
		color: $blue;
		font-weight: 600;
	}

	td.in-range {
		background-color: $blue;
		border-color: transparent;
		color: $dark-grey;
		border-radius: 0;
	}

	.drp-calendar {
		display: none;
	}

	.drp-calendar.left {
		position: relative;
		padding: 30px 26px;
	}

	.drp-calendar.right {
		padding: 26px;
		position: relative;
	}

	.calendar-table {

		th.prev {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}

		th.next {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}

	}

	.drp-selected {
		font-size: 1.5rem !important;
	}

	.drp-buttons {

		.btn {
			font-weight: 400 !important;
		}

	}

}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	min-width: 32px;
	width: 32px;
	height: 24px;
	line-height: 24px;
	font-size: 14px;
	cursor: pointer;
}

.daterangepicker .calendar-table th.prev span, .daterangepicker .calendar-table th.next span {
	border-color: $blue;
}

.daterangepicker .calendar-table th.prev:hover, .daterangepicker .calendar-table th.next:hover {
	background-color: transparent;
}

.drp-calendar.left:after {
	display: inline-block;
	content: ' ';
	width: 1px;
	height: 66%;
	background: $blue-grey;
	transform: translateY(-50%);
	position: absolute;
	top: 50%;
	right: 0;
}

.daterangepicker.show-calendar {

	.drp-buttons {
		display: flex !important;
		border-top: none;
		text-align: center;
		align-items: center;
		padding: 0 28px 28px 28px;
	}

}

.drp-selected {
	width: 50%;
	align-items: center;
}

.daterangepicker .drp-buttons .cancelBtn,
.daterangepicker .drp-buttons .applyBtn {
	width: 25%;
	padding: .8rem !important;
	font-size: 14px !important;
}
.daterangepicker .drp-buttons .cancelBtn{
	background: none;
	border: 1px solid $natural-grey;
	box-shadow: none;
}
.daterangepicker .drp-buttons .applyBtn{
	background-color: $blue;
	background-image: none;
	background-repeat: no-repeat;
	border-color: transparent;
}
.multiselect-selected-text {
	color: $dark-grey;
}

select {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	background-color: transparent;
	border-radius: 3px;
	padding: 2px;
	border: 1px solid $blue-grey;
}

label {
	font-weight: 400!important;
}

