#edit-profile-admin{
    width: 100%;
    display: flex;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 2rem;
    cursor: pointer;
    margin: 0;
    .flex{
        width: 100%;
    }
    .title-legal{
        font-size: 18px;
        font-weight: 500;
        color: $grey;
        padding: 0;
    }
    #save-info-container{
        strong{
            font-weight: 500;
            margin-left: 1rem;
            color: $blue;
        }
    }
    .payer{
        width: 100%;
        position: relative;
        padding-bottom: 1rem;
        .delete_payer{
            position: absolute;
            right: 0;
            top: 10px;
        }
        table{
            width: 100%;
            td{
                width: 100%;
            }
        }
        .chosen-container{
            border: 1px solid $blue-grey;
            padding: 1.2rem;
            border-radius: 3px;
            .chosen-single{
                padding-left: 0;
                font-weight: 400;
            }
        }
        .grey-name{
            font-size: 24px;
            color: $blue;
        }
    }
    .menu-admin{
        width: 35%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .grey-name{
            font-size: 16px;
            color: $light-grey;
            margin-bottom: 2rem;
        }
        ul{
            padding-left: 0;
            li{
                margin-bottom: 2rem;
                a{
                    font-size: 16px;
                    color: $light-grey;
                    &.active{
						position: relative;
						color:$blue;
						font-weight: 500;
					}
					&.active:before{
						content: "\f061";
						font-family: "Font Awesome 5 Pro";
						margin-right: 1rem;
						position: absolute;
						left: -3rem;
				
					}
                }
            }
        }
    }
    .admin-top-menu{
        display: flex;
        justify-content: space-between;
        padding-top: 3rem;
        .name-page-admin{
            font-size: 2.4rem;
            color: $blue;
            font-weight: 500;
        }
    }
    .table-admin{
        width: 85%;
        td{
            border: none;
            padding-top: 1rem;
            padding-bottom: 1rem;
            color: #5c5c5c;
            font-weight: 500;
            padding-right: 0;
        }
        .blue-cursor-button{
            padding-right: 0;
        }
        .modal-body{
            padding: 0;
        }
        p{
            color: $light-grey;
            font-weight: 400;
            margin-left: 1rem;
        }
        .fa-plus{
            margin-right: 1rem;
        }
        input{
            border: 1px solid $blue-grey;
            padding: 1.2rem;
            border-radius: 3px;
            &[type=checkbox]{
				padding: 0;
            }
            &::placeholder{
                font-size: 14px;
            }
        }
        #pseudo-input{
            @extend input;
            color: $dark-grey;
            font-weight: 400;
            font-size: 14px;
        }
        textarea{
            border: 1px solid $blue-grey;
        }
        .file-caption{
            @extend input;
            border-right: none;
            box-shadow: none;
        }
        #container-checkbox{
            width: 80px;
            margin-bottom: 2rem;
        }
        .city_show{
             margin-bottom: 2rem;
        }
        .input-city{
            @extend input;
            width: 100%;
            margin-bottom: 2rem;
        }
        .input-region{
            @extend input;
            width: 100%;
            margin-bottom: 2rem;
        }
        .fa-times{
            color: $red;
            margin-left: 1rem;
            &:hover{
                color: $dark-red;
            }
        }
        .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group{
            background-color: $white;
            border: 1px solid $blue-grey;
            border-left: none;
        }
        .select-legal{
            border: 1px solid $blue-grey;
            height: 4.5rem;
            width: 100%;
            outline: none;
            &::placeholder{
                font-size: 14px;
            }
            option{
                background-color: $fog-grey;
                outline: none;
            }
        }
        .multiselect.dropdown-toggle.btn{
            @extend input;
            width: 100%;
            .multiselect-selected-text{
                float: left;
                font-size: 14px;
            }
            .fa-sort{
                color: $blue-grey;
            }
        }
        .multiselect-container.dropdown-menu{
			width: 100%;
			background: $fog-grey;
			box-shadow: none;
			border: none;
			.checkbox input[type=checkbox]{
				margin-right: 1rem;
			}
			
        }
        .dropdown-menu > .active > a{
            background-color: transparent;
            background-image: none;
            background-repeat: no-repeat;
            color: $dark-grey;
        }
        .EmpGroups{
            .btn-group{
                width: 100%;
            }
        }
        .fa-download{
            color: $blue;
            font-size: 1.8rem;
        }
        .file-preview{
            border: 1px solid $blue-grey;
            border-radius: 3px;
            margin-bottom: 2rem;
            .fa-times{
                margin-right: 1rem;
                margin-top: 1rem;
                color: $dark-grey;
            }
        }
        .name-employees{
            font-size: 16px;
            font-weight: 500;
            color: $dark-grey;
            margin-top: 1rem;
        }
        .info-employees{
            font-weight: 400;
            padding-top: 2rem;
           padding-bottom: 2rem;
        }
        .sinlge_role{
            background-color: $fog-grey;
            padding: 1rem;
            font-weight: 400;
            color: $dark-grey;
            box-shadow: 0px 2px 6px rgba(96, 111, 120, 0.537109);
            display: inline-block;
            font-size: 13px;
            margin-right: 1rem;
        }
        .container-icon{
            text-align: right;
            .fa-trash-alt{
                color: $red;
                margin-left: 1rem;
            }
        }
    }
    .alert-danger-emloyee{
        background-image: none;
        background-repeat: no-repeat;
        background-color: $white;
        border: none;
        box-shadow: none;
        padding-left: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        font-size: 16px;
        color: $red;
    }
    #separator{
        display: inline-block;
        content: ' ';
        width: 2px;
        height: 2rem;
        background: $blue-grey;
        margin: 0 2rem;
        transform: translateY(80%);
    }
    .small-title{
        font-size: 2.4rem;
        font-weight: 500;
        color: $blue;
        padding-top: 1rem;
    }
    .icon-button{
        padding: 0;
    }
    #add-legal-entity{
        border: none;
        color: $blue;
        padding-left: 0;
    }
    .note{
        padding-top: 1rem;
        color: $light-grey;
    }
    .context-window{
        #modal-body-admin{
            margin-left: 2rem;
            margin-right: 2rem;
        }
        .modal-name{
            font-size: 2.4rem;
            color: $blue;
            font-weight: 500;
        }
        .container-modal{
            outline: none;
        }
        .button-accent{
            margin-top: 1rem;
        }
        
        .nav-tabs{
            border: none;
        }
        .nav-tabs > li.active > a{
            border: 1px solid transparent;
            padding-left: 0;
            margin-left: 2rem;
            font-weight: 500;
            font-size: 2.4rem;
            color: $blue;
        }
        .nav-tabs > li > a{
            font-weight: 500;
            color: $smoke-grey;
            font-size: 2.4rem;
        }
        .nav-tabs > li > a:hover{
            background-color: transparent;
            border: 1px solid transparent;
        }
        .fa-times{
            color: $light-grey;
            padding-left: 1rem;
            &:hover{
                color: $dark-grey;
            }
        }
    }
    #add-legal-entity{
        padding-right: 0;
    }
    #edit_legal_button{
        padding-right: 0;
    }
    .table-legal{
        .alert-danger{
            background-color: $white;
            background-image: none;
            background-repeat: no-repeat;
            color: $red;
            font-weight: 400;
            font-size: 15px;
        }
        
        .container_add_button{
            padding-top: 1rem;
            a{
                font-weight: 400;
                color: $white;
                text-decoration: none;
            }
        }
    }
}
.service{
    border: 1px solid $blue-grey;
    border-radius: 3px;
    margin-bottom: 3rem;
    padding: 2rem;
    color: $dark-grey;
    .title-service{
        font-size: 2rem;
        color: $grey;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    h3{
        margin-top: 0;
        font-weight: 700;
        font-size: 2rem;
        color: $grey;
    }
}
#license-form{
    .button-border{
        padding-left: 0;
        margin-top: 3rem;
    }
    .fa-plus{
        color: $blue;
        margin-right: 1rem;
    }
    .file-preview{
        border: 1px solid $blue-grey;
        border-radius: 3px;
        margin-bottom: 2rem;
        .fa-times{
            margin-right: 1rem;
            margin-top: 1rem;
            color: $dark-grey;
        }
    }
}
.name-page-moderation{
    margin-bottom: 3rem;
    font-size: 18px;
    color: $dark-grey;
    font-weight: 500;
}
#license-table{
    td{
        border-bottom: 1px solid $blue-grey;
        padding: 10px;
        padding-left: 0;
        vertical-align: baseline;
        border-top: none;
    }
}
.create_error{
    color: $red;
}
.menu-creating{
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
#table-create-company{
    border-top: none;
    td{
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: none;
        color: $grey;
        font-weight: 500;
        width: 100%;
        padding-right: 0;
    }
    .title-table{
        font-size: 18px;
        color: $dark-grey;
    }
    input[type=text]{
        border: 1px solid $blue-grey;
        padding: 1.2rem;
        width: 100%;
    }
    input[type=checkbox]{
        margin-right: 1rem;
    }
    textarea{
        border: 1px solid $blue-grey;
        padding: 1.2rem;
        width: 100%;
    }
    .fa-plus{
        color: $blue;
        margin-left: 1rem;
        cursor: pointer;
        &:hover{
            color: $dark-blue;
        }
    }
    #phone_add{
        input[type=text]{
            width: 50%;
        }
        td{
            padding-top: 0;
            padding-bottom: 1rem;
        }
        #delete-phone{
            padding-top: 2rem;
            .fa-trash-alt{
                color: $red;
                margin-right: 1rem;
                cursor: pointer;
                &:hover{
                    color: $dark-red;
                }
            }
        }
        #note{
            font-size: 15px;
            font-weight: 400;
            color: $light-grey;;
        }
        
    }
    #mail_add{
        @extend #phone_add;
        #delete-mail{
            @extend #delete-phone;
        }
    }
}
#container-account-settings{
    #account-settings{
        div{
            margin-bottom: 2rem;
            &:last-child{
                margin-bottom: 0;
            }
        }
        .acount-text{
            margin-right: 2rem;
            color: $grey;
            font-weight: 500;
            font-size: 15px;
        }
        .fa-edit{
            margin-left: 2rem;
        }
    }
    .buttons-account{
        margin-top: 4rem;
        .button-accent{
            margin-right: 2rem;
        }
    }
}
#container-change-password{
    width: 850px;
    margin: 0 auto;
    #change-password-form{
        margin-top: 3rem;
        .menu-password{
            display: flex;
            justify-content: space-between;
        }
        .cancel-button{
            margin-right: 2rem;
            color: $dark-grey;
            font-size: 15px;

        }
        #table-change-passwort{
            width: 100%;
            td{
                padding-top: 2rem;
                width: 100%;
                color: $grey;
                font-weight: 500;
                font-size: 15px;
                input{
                    border: 1px solid $blue-grey;
                    padding: 1.2rem;
                    width: 100%;
                }
            }
            .alert-danger{
                color: $red;
                font-weight: 400;
                font-size: 15px;
                background-color: transparent;
                background-image: none;
                background-repeat: no-repeat;
                
            }
        }
    }
}
#container-change-profile{
    @extend #container-change-password;
    .change-profile-form{
        margin-top: 3rem;
        .menu-change-profile{
            display: flex;
            justify-content: space-between;
            .grey-button{
                margin-right: 2rem;
                font-size: 15px;
            }
        }
        #change-profile-table{
            width: 100%;
            td{
                padding-top: 1rem;
                padding-bottom: 1rem;
                color: $grey;
                font-size: 15px;
                font-weight: 500;
                input{
                    border: 1px solid $blue-grey;
                    padding: 1.2rem;
                    height: unset;
                    font-size: 14px;
                }
                select{
                    border: 1px solid $blue-grey;
                    height: 5rem;
                }
            }
        }
    }
}
.moderation-container{
    .button-border{
        margin-left: 2rem;
        padding-right: 0;
        .fa-plus{
            margin-right: 1rem;
        }
    }
}