@font-face {
    font-family: 'Gotham';
    font-weight: 100;
    font-style: normal;
    src: url('../fonts/Gotham-Thin.woff2') format('woff2'),
    url('../fonts/Gotham-Thin.woff') format('woff');;
}

@font-face {
    font-family: 'Gotham';
    font-weight: 200;
    font-style: normal;
    src: url('../fonts/Gotham-ExtraLight.woff2') format('woff2'),
    url('../fonts/Gotham-ExtraLight.woff') format('woff');;
}

@font-face {
    font-family: 'Gotham';
    font-weight: 300;
    font-style: normal;
    src: url('../fonts/Gotham-Light.woff2') format('woff2'),
    url('../fonts/Gotham-Light.woff') format('woff');;
}

@font-face {
    font-family: 'Gotham';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
    url('../fonts/Gotham-Bold.woff') format('woff');;
}

@font-face {
    font-family: 'Gotham';
    font-weight: 600;
    font-style: normal;
    src: url('../fonts/Gotham-Black.woff2') format('woff2'),
    url('../fonts/Gotham-Black.woff') format('woff');;
}


@font-face {
    font-family: 'Gotham';
    font-weight: 600;
    font-style: normal;
    src: url('../fonts/Gotham-Ultra.woff2') format('woff2'),
    url('../fonts/Gotham-Ultra.woff') format('woff');;
}


@font-face {
    font-family: 'Gotham';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/Gotham-Book2.woff2') format('woff2'),
    url('../fonts/Gotham-Book.woff') format('woff');;
}
