
.dashboard {
	.content {
		margin: 20px 0;
	}

}

.dashboardHeader {
	background-color: $white;
	border-bottom: 1px solid $natural-grey;
	height: 50px;
	.promoatlasLogo {
		display: block;
		font-size: 32px;
		float: left;
		width: 40px;
		height: 40px;
		color: transparent !important;
		background-size: 100%;
		background-position: center;
		background-repeat: no-repeat;
	}

	.headerNav {

		li {
			display: inline-block;
			list-style: none;
		}

	}

	a:hover {
		text-decoration: underline;
	}

}

.filters {
	padding-top: 2px;
	font-size: 10px;
	text-align: left;
}

.activeFilters {
	padding: 8px;
	background-color: $light-blue;
	color: $grey;
	border-radius: 3px;
	display: inline-block;
	font-size: 1.1rem;
	a {
		margin-left: 5px;
		padding: 4px;
		color: $red;
		font-size: 1.1rem;
	}

}

.messages {
	margin-bottom: 10px;
	background-color: $light-green;
	width: 70%;
	padding: 20px;
	word-wrap: break-word;
	border-radius: 10px;
}

.messages.user {
	margin-left: 20%;
	background-color: $gelb;
}

.dashboardHeader a,
.dashboardTopMenu .TopMenu a {
	color: #5B5B5B;
}

.dashboardTopMenu {
	background-color: $white;
	height: 50px;
	.TopMenu {
		display: block;
		padding: 0;
		white-space: nowrap;
		li {
			display: inline-block;
			list-style: none;
			padding: 15px 0;
			a {
				border-left: 1px solid $light-grey;
				line-height: 20px;
				padding: 0 14px 0 16px;
			}

		}

		li:first-of-type {

			a {
				padding-left: 0;
				border-left: 0;
			}

		}

	}

}

.companyInfo {
	text-align: center;
	.avatar {
		height: 90px;
		width: 90px;
		img {
			max-height: 90px;
			max-width: 90px;
		}

	}

}

.companyName {
	margin-bottom: 5px;
}

.companyContacts {
	display: block;
	padding-left: 0;
	li {
		list-style: none;
		position: relative;
		padding-left: 25px;
		text-align: left;
		display: block;
		color: $light-grey;
		text-transform: uppercase;
		margin: 10px 0;
		a {
			text-transform: none;
			color: $dark-grey;
		}

	}

	i {
		position: absolute;
		top: 4px;
		left: 0;
	}

}

.Employees {

	.tab-content {
		background-color: $white;
		border-bottom: 1px solid $natural-grey;
		margin-left: -20px;
		margin-right: -20px;
		margin-bottom: 20px;
		padding: 0 20px;
		ul {
			padding: 0;
			margin: 0;
			li {
				border-bottom: 1px solid $natural-grey;
				list-style: none;
				padding: 20px 0;
			}

			li:last-of-type {
				border-bottom: 0;
			}

		}

	}

	.nav-tabs {
		margin-left: -20px;
		margin-right: -20px;
		li {
			width: 50%;
			a {
				margin-right: 0;
				padding: 10px 20px;
				color: $light-grey;
			}

		}

		li:first-of-type {

			a {
				border-left: 0;
				-moz-border-radius-topleft: 0;
				border-top-left-radius: 0;
			}

		}

		li:last-of-type {

			a {
				border-right: 0;
				-moz-border-radius-topright: 0;
				border-top-right-radius: 0;
			}

		}

	}

}

.lastChangesID {
	width: 10%;
	overflow: hidden;
}

.lastChangesName {
	max-width: 150px;
	width: 20%;
	overflow: hidden;
}

.lastChangesField {
	max-width: 150px;
	width: 21%;
	overflow: hidden;
}

.lastChangesOld {
	max-width: 203px;
	width: 22%;
	overflow: hidden;
}

.lastChangesNew {
	max-width: 203px;
	width: 22%;
	overflow: hidden;
}

.InvoiceLocation {
	padding-left: 15px !important;
	position: relative;
	i {
		position: absolute;
		top: 12px;
		left: 0px;
	}

}
