.react-select-container{
    display: inline-block;
    width: 150px;
    .react-select__control{
        border: none;
        &::after{
            display: inline;
            content: "\f078";
            font-family: "Font Awesome 5 Pro";
            position: absolute;
            right: 0;
            color: $grey;
        }
    }
    .react-select__control--is-focused{
        border-color: transparent;
        box-shadow: none;
        &:hover{
            border-color: transparent;
        }
    }
    .react-select__indicator-separator{
        display: none;
    }
    .react-select__placeholder{
        font-size: 15px;
        color: $dark-grey;
    }
    .react-select__value-container{
        padding-left: 0;
        outline: none;
        
    }
    .react-select__menu{
        background-color: $fog-grey;
    }
    svg{
        display: none;
    }
   
}
.select-pseudo-input{
    width: 100%;
    padding: .5rem;
    border: 1px solid $blue-grey;
    border-radius: 3px;
    font-weight: 400;
    &.small{
        padding: .3rem;
    }
    .react-select__menu{
       left: 0;
    }
    .react-select__single-value{
       padding-left: 5px;
    }
    .react-select__option.react-select__option--is-selected{
        background-color: $blue;
    }
    .css-1fhf3k1-control{
        background-color: transparent;
    }
    .css-1rhbuit-multiValue{
        background-color: $fog-grey;
    }
    
    &.react-select--is-disabled{
        opacity: 0.53;
    }
    .css-f0arjc-control{
        background-color: transparent;
    }
}