.container-input{
    background-color: $fog-grey;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    .input-result{
       padding-top: 1rem;
       padding-left: 1.3rem;
       &:last-child{
           padding-bottom: 1rem;
       }
    }
}