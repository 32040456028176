#table-document-client{
	@extend #table-contractor;
	a{
		padding: 0;
	}
}
#table-contractor{
	overflow-x: scroll;
	td{
		vertical-align: middle;
		border-top: solid 1px $blue-grey;
		border-bottom: solid 1px $blue-grey;
		padding: 10px;
		padding-left: 0;
	}
	.has-select {
		min-width: 185px;
		color: $grey;
		font-weight: 500;
		font-size: 15px;
	}

	.has-select-small {
		min-width: 145px;
		color: $grey;
		font-weight: 500;
		font-size: 15px;
	}

	.has-select-large {
		min-width: 245px;
		color: $grey;
		font-weight: 500;
		font-size: 15px;
	}
	input{
		font-weight: 500;
		color: $grey;
		font-size: 15px;
	}
	.search-field:after{
		margin-left: 2rem;
	}
	thead{
		display: none;
	}
	.row{
		padding-left: 0;
	}
	th{
		color: $light-grey;
		font-size: 15px;
		font-weight: 500;
		vertical-align: middle;
		
	}
}
.schema-document{
	select{
		border: none;
		border-bottom: 1px solid $blue-grey;
		margin-left: 2rem;
		width: 7rem;
	}
	.form-group{
		margin-bottom: 3rem;
	}
}
.input-small{
	widows: 100px;
}
.input-medium{
	width: 150px;
}
.input-large{
	width: 180px;
}
.input-xl{
	width: 200px;
}
#agile-window-content{
	.modal-header{
		padding: 0;
	}
	.modal-body{
		padding: 0;
		font-size: 15px;
	}
	.modal-button-group{
		margin-top: 2rem;
		margin-bottom: 2rem;
		button{
			margin-right: 2rem;
			padding-left: 0;
		}
	}
	#modal-form{
		input[type=checkbox]{
			margin-right: 2rem;
			
		}
	}

}
.table-responsive{
	overflow-x: scroll;
}