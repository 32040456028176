.menu-edit-order{
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;
	margin-top: 2rem;
}

#edit-order{
	input{
		border: 1px solid $blue-grey;
    	padding: 1.2rem;
		border-radius: 3px;
		font-size: 14px;
		&[type=checkbox]{
			padding: 0;
			
		}
		&[type=text]{
			width: 100%;
		}
	}
	textarea{
		width: 100%;
		@extend input;
	}
	.title-edit-order{
		padding-top: 2rem;
		padding-bottom: 2rem;
		color: $light-grey;
		font-size: 15px;
		font-weight: 500;
		box-shadow: none;
		background-image: none;
	}
	select{
		width: 100%;
		@extend input;
	}
	.btn-group{
		width: 100%;
	}
	.multiselect.dropdown-toggle.btn{
		width: 100%;
		border: 1px solid $blue-grey !important;
		padding: 1.2rem;
		border-radius: 3px;
		.multiselect-selected-text{
			float: left;
		}
		.fa-sort{
			color: $blue-grey;
			margin-left: 0;
		}
	}
	.multiselect-container.dropdown-menu{
		width: 100%;
		background: $fog-grey;
		box-shadow: none;
		border: none;
		label{
			background-color: $fog-grey;
			color: $dark-grey;
			align-items: center;
		}
		input{
			padding: 0px;
			margin-right: 1rem;
			
			&[type=checkbox]:after{
				padding: 3px;
				border: 1px solid $blue;
			}
		}
		
	}
	.notes{
		padding-top: 2rem;
		color: $light-grey;
		font-weight: 400;
	}
	.include{
		display: flex;
	}
	.type_cities{
		padding: 0;
		&[type=checkbox]{
			padding: 0;
			margin-right: 1rem;
		}
	}
	.date-edit{
		display: flex;
		justify-content: space-between;
		#client_bill_dateEnd{
			display: flex;
			#client_bill_dateEnd_year,
			#client_bill_dateEnd_day{
				width: 10rem;
				margin-left: 2rem;
			}
			#client_bill_dateEnd_month{
				width: 10rem;
			}
		}
		#client_bill_dateStart{
			display: flex;
			#client_bill_dateStart_month,
			#client_bill_dateStart_year,
			#client_bill_dateStart_day{
				width: 10rem;
				margin-right: 2rem;
			}
		}
		.date{
			padding-bottom: 2rem;
    		color: $light-grey;
		}
    }
    .input-lg{
        padding-left: 0;
    }
}