$white: #ffffff;
$pearl: #dddddd;
$chiffon: #FAFCF5;
$creamy-grey: #e4e6e7;
$blue-grey: #C5CFDC;
$light-grey: #868686;
$shadow: rgba(96, 111, 120, 0.537109);
$natural-grey: #eaeaea;
$grey: rgb(109, 109, 109);
$smoke-grey: #a9a9a9;
$dark-grey: #444;
$black: rgba(0, 0, 0, 0);
$fog-grey: #F4F5F7;
$light-blue: #e9f3fa;
$sky: #66afe9;
$blue: #2c7bb2;
$dark-blue: rgb(17, 70, 131);
$sunrise: #f6af40;
$salamander: #ff6d00;
$gelb: rgb(212, 212, 124);
$pistachio: #c8cfcd;
$lime: rgb(142, 188, 63);
$light-green: #b9f5a5;
$rose: #eab9b9;
$coral: #FF7C7C;
$red: #d2322d;
$dark-red: #9b1c17;