.container-login{
    width: 800px;
    margin: 0 auto;
    margin-top: 10rem;
    box-shadow: 2px 4px 4px rgba(96, 111, 120, 0.537109);
    padding: 2rem;
    .name-page-login{
        font-size: 2.4rem;
        font-weight: 500;
        color: $blue;
    }
    input{
        padding: 1.2rem;
        width: 100%;
        border: 1px solid $blue-grey;
        border-radius: 3px;
        margin-top: 2rem;
    }
    .btn-group-admin{
        margin-top: 2rem;
        .button-accent{
            margin-right: 2rem;
        }
    }
}