.context-window {
	position: fixed;
	top: 0;
	right: -100%;
	background-color: $white;
	width: 50%;
	height: 100%;
	overflow-y: scroll;
	z-index: 1200;
	padding: 4em 2em;
	box-shadow: 0px 2px 20px $shadow;
	transition: right .4s ease-in-out;
}

.context-window.standalone {
	opacity: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	height: initial;
	overflow: auto;
}

.context-window.layer-2 {
	width: 45%;
	z-index: 1202;
}

.context-window.layer-2 + .context-window-backdrop {
	z-index: 1201;
}

.context-window + .context-window-backdrop,
.general-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(96,111,120, .75);
	opacity: 0;
	pointer-events: none;
	z-index: 100;
	transition: opacity .4s ease-in-out;
}

.context-window.active + .context-window-backdrop,
.general-backdrop {
	opacity: 1;
	pointer-events: all;
}

.context-window.active {
	opacity: 1;
	pointer-events: all;
	right: 0;
}

.context-window-close {
	position: absolute;
	top: 1em;
	left: 2em;
	cursor: pointer;
	color: #606F78;
	transition: color .2s ease-in-out;
}

.context-window-close:hover {
	color: #2B69B2;
}

.context-window-close:before {
	font-size: 2em;
}

.context-window.loading {
	overflow: hidden;
}

.context-window-content {
	height: calc(100% - 3em);
	overflow: auto;
}

.context-window-footer.sticky {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	padding: 1em 2em 2em 2em;
}

.context-window-overlay {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	background-color: rgba(255, 255, 255, .9);
	z-index: 2;
	.loading-glyph {
		font-size: 5em;
		position: absolute;
		top: 48%;
		left: 48%;
		transform: translate(-50%, -50%);
		color: #2B69B2;
	}

}

.context-window-overlay.active {
	display: initial;
}