#info-mediaplan{
	span{
		font-size: 14px;
		font-weight: 400;
		color: #333;
		strong{
			margin-left: 2rem;
			color: $blue;
			font-weight: 400;
		}
	}
	select{
		border: none;
		border-bottom: 1px solid $blue-grey;
		margin-left: 2rem;
		box-shadow: none;
		margin-right: 2rem;
		color: $blue;
	}
}
#button-mediaplan{
	select{
		border: none;
		border-bottom: 1px solid $blue-grey;
		margin-left: 2rem;
		box-shadow: none;
		margin-right: 2rem;
		color: $blue;
		font-size: 14px;
	}
}
#edit-templates-modal{
	.context-window-content{
		margin-top: 2rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}
	.context-window-footer{
		margin-top: 2rem;
		padding-left: 2rem;
		margin-bottom: 2rem;
	}
	.context-window-close{
		padding-left: 2rem;
		margin-top: 2rem;
	}
	.name-page{
		margin-bottom: 2rem;
	}
	input[type=checkbox]{
		margin-right: 1rem;
	}
	input::placeholder{
		font-size: 14px;
		color: $light-grey;
	}
	.select-type{
		margin-bottom: 2rem;
	}
	.delete-template{
		margin-bottom: 2rem;
		color: $red;
		cursor: pointer;
		&:hover{
			color: $dark-red;
		}
	}
	.fa-trash-alt:before{
		margin-right: 1rem;
	}
	
}
.map-icon{
	margin-left: 0.7rem;
}
#main-table{
	.item-filter{
		width: 100%;
		position: relative;
		
	}
	.name-dropdown{
		color: $blue;
		font-size: 15px;
		font-weight: 500;
		padding-left: 0;

	}
	.fa-sort{
		color: $blue;
	}
	.filter-switch{
		display: flex;
		a {
			border-radius: 3px;
			padding: 0.5rem 1rem;
			color: $dark-grey;
			width: 50%;
			white-space: nowrap;
			text-align: center;
			font-weight: 400;
			border: 1px solid $blue-grey;
		}
		
		a.active {
			background: $blue;
			color: $white;
			border: none;
		}
		a:first-child{
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right: none;
		}
		a:last-child{
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.block-input-line{
		margin-bottom: .5rem;	
	}
	.input-line{
		border: none;
		border-bottom: 1px solid $blue-grey;
		background-color: $fog-grey;
		padding-left: 0;
		&::placeholder{
			padding-left: 2rem;
			font-size: 14px;
		}
	}
	input:not(:placeholder-shown)+.pseudo-icon:before{
		display: none;
	}
	.pseudo-icon:before{
		position: absolute;
		content: "\f002";
		display: inline-block;
		font-family: "Font Awesome 5 Pro";
		color: $light-grey;
		top: 15%;
		left: 0;
		transform: translateY(-15%);
	}
	.tip{
		padding-top: 3rem;
		font-size: 15px;
		color: $light-grey;
	}
	.filter-media{
		display: flex;
		flex-direction: column;
		div{
			margin-bottom: .5rem;
			vertical-align: middle;
		}
		input[type=radio]{
			visibility: hidden;
			margin-right: .5rem;
			margin-top: 0;
			margin-bottom: 0;
			vertical-align: text-top;
		}
		input[type=radio]:before{
			content: '\f111';
			font-family: "Font Awesome 5 Pro";
			display: inline-block;
			visibility: visible;
			font-style: normal;
			font-weight: normal;
			color: $blue-grey;
			cursor: pointer;			
		}
		input[type=radio]:checked:before {
			content: '\f192';
			font-family: "Font Awesome 5 Pro";
			color: $blue;
		}	
	}
		.save-filter{
			background: $blue;
			border-radius: 3px;
			padding: 0.5rem 1rem;
			color: $white;
			font-size: 14px;
			margin-top: 1.5rem;
			font-family: 'Gotham', sans-serif;
		}
	select{
		border: 1px solid $blue-grey;
		box-shadow: none;
		padding: .6rem;
		color: $light-grey;
	}
	.form-control{
		@extend select;
		padding: .5rem;
	}
	.note-table{
		font-size: 12px;
		color: $light-grey;
		margin-left: .7rem;
	}
	thead >{
		tr>{
			td{
				padding-right: 2rem;
				padding-left: 0;
				color: $light-grey;
				a{
					color: $blue;
					font-size: 15px;
					font-weight: 500;
				}
				.fa-sort{
					margin-left: .5rem;
				}
			}
		}
	}
	tbody>{
		tr>{
			td{
				border-top: 1px solid $blue-grey;
			}
		}
	}
	td{
		padding-left: 0;
		vertical-align: middle;
	}
	.dropdown-item{
		.fa-trash-alt{
			color: $red;
		}
		.fa-map-marker-alt{
			color: $blue;
		}
		.fa-undo{
			color: $gelb;
			margin-right: .5rem;
		}
	}
}
.fixed {
	min-height: auto !important;
}

.form-control.file-caption.kv-fileinput-caption {
	padding: 1.2rem;
	border: 1px solid $blue-grey;
	border-radius: 0;
	background-color: rgba(197,207,220, .15);
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

#filter-upload{
	margin-top: 2rem;
	#show_table_preview{
		margin-right: 2rem;
	}
	#open-template-window{
		margin-top: 2rem;
		padding-left: 0;
	}
}
#template-window{
	overflow-y: hidden;
	.context-window-content{
		margin-top: 2rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}
	.context-window-footer{
		margin-top: 2rem;
		padding-left: 2rem;
		margin-bottom: 2rem;
	}
	.context-window-close{
		padding-left: 2rem;
		margin-top: 2rem;
	}
}
#import-xls{
	.name-import-xls{
		font-size: 2.8rem;
		color: $blue;
		font-weight: 500;
		margin-bottom: 2rem;
	}
	.select-type{
		margin-bottom: 2rem;
		font-size: 14px;
	}
	.note{
		color: $light-grey;
		margin-bottom: 2rem;
	}
	#add_template{
		margin-bottom: 2rem;
		label{
			margin-left: 2rem;
		}	
	}
	.input_add_template{
		margin-bottom: 2rem;
		input{
			color: $light-grey;
			border: 1px solid $blue-grey;
			padding: 1.2rem;
			width: 100%;
			&::placeholder{
				font-size: 14px;
			}
		}
	}
	#change_template{
		@extend #add_template;
	}
	#delete_template{
		margin-bottom: 2rem;
	}
	
}
	#alert_importXLS{
		margin-top: 2rem;
		padding-left: 0;
		border-radius: 3px;
		background-color: transparent;
		color: $red;
		background-image: none;
		background-repeat: no-repeat;
		font-size: 15px;
	}
.uploadCSV {
	#columns_add {
		.row {
			display: flex;
			margin-bottom: 2rem;
			margin-left: 0;
			margin-right: 0;
			> {

				* {

					> {

						* {
							height: 100%;
							border-radius: 3px;
							border: 1px solid $blue-grey;
							box-shadow: none;
							padding: 1.2rem;
							font-size: 14px;
							&::placeholder{
								font-size: 14px;
							}
						}

					}

				}

				*:nth-child(3) {
					width: 10%;
					> {

						* {
							display: flex;
							align-items: center;
							justify-content: center;
							cursor: pointer;
							> {

								i {
									height: 50%;
									margin: 0 .5rem;
								}

								i.fa-plus {
									color: $blue;
								}

								i.fa-plus:hover {
									color: $dark-blue;
								}

								i.fa-minus {
									color: $red;
								}

								i.fa-minus:hover {
									color: $dark-red;
								}

							}

						}

					}

				}

			}

		}

	}

}

.uploadCSV #columns_add .row > *:nth-child(1),
.uploadCSV #columns_add .row > *:nth-child(2) {
	width: calc(45% - 2rem);
	margin-right: 2rem;
	text-align: left;
	padding: 0;
}
#modal_export_mediaplan{
	.context-window-content{
		margin-top: 2rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}
	.modal-header-mediaplan{
		margin-top: 2rem;
		padding-left: 2rem;
	}
	.modal-footer-mediaplan{
		padding-right: 2rem;
	}
	.context-window-close{
		padding-left: 2rem;
		margin-top: 2rem;
	}
	.name-page-client{
		margin-bottom: 2rem;
		padding-left: 2rem;
	}
	.list_element{
		border-top: 1px solid $blue-grey;
		label {
			margin: 10px;
		}
	}
	.modal_export_button,
	.group-button-modal{
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	#export-template-select{
		margin-bottom: 2rem;
		font-size: 14px;
	
	}
	#export-template-input{
		margin-top: 2rem;
		font-size: 14px;
	}
	.modal-footer-mediaplan {
		text-align: right;
		.border-blue-btn{
			margin-right: 2rem;
		}
	}
	.fa-trash-alt:before{
		margin-right: 1rem;
	}
	.delete-template{
		margin-bottom: 2rem;
		color: $red;
		cursor: pointer;
		&:hover{
			color: $dark-red;
		}
	}

}
.modal-header-mediaplan {
	border-bottom: none;
	margin-bottom: 2rem;
}

.modal-media-mediaplan {
	padding: 0;
}

.modal-footer-mediaplan {
	text-align: right;
}
#table-export-ap{
	.sort-title{
		color: $blue;
    	font-size: 15px;
    	font-weight: 500;
	}
	.fa-trash{
		color: $red;
		margin-right: .4rem;
	}
	.mediaplan_checkbox{
		vertical-align: middle;
	}
}