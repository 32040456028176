#map-wrapper, #photo-wrapper {
	right: 0;
	top: 0px;
	bottom: 0;
	width: 50%;
	position: fixed;
	z-index: 999;
	overflow: visible;
	transform: translateX(calc(100% - 48px));
	transition: transform 0.5s ease-out 0s;
	-webkit-transition: transform 0.5s ease-out 0s;
}

#map-wrapper.active, #photo-wrapper.active {
	width: 50%;
	transform: none;
}

#map-wrapper.active {

	#map-icon {
		transform: rotate(180deg);
	}

}

.map-sticky-container {
	z-index: 105;
	position: absolute;
	right: calc(100% - 48px + 4px);
	margin-top: 3.5rem;
	display: flex;
	align-items: center;
}

.inner_wrapper {
	background-color: $white;
	position: relative;
	z-index: 101;
	width: 100%;
	height: 100%;
	padding: 0;
}

.item {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: $pearl;
	.active {
		position: relative;
		overflow: hidden;
		width: 100%;
	}

}

.side-shadow {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 2em;
	background: linear-gradient(to right, rgba(197,207,220, .45) 0%,$black 100%);
	z-index: 101;
}

.address_mediaplan.active {
	background-color: $light-green !important;
}

.address_mediaplan:hover {
	background-color: $light-green;
	cursor: pointer;
}

.address_mediaplan {
	text-align: left;
	border: 1px solid $grey;
	margin: 5px;
	padding: 5px 20px;
	border-radius: 4px;
}

.but_toggle {
	color: $dark-grey;
	background-color: $light-blue;
	span {
		text-transform: uppercase;
		padding-right: 10px;
	}

}

#myCarousel {
	background-color: $white;
	border: 1px solid $pearl;
	border-radius: 4px;
	z-index: 101;
	height: 100%;
	width: 100%;
	.carousel-inner {

		.iframe {
			height: 100%;
			width: 100%;
		}

	}

	.nav {
		margin-bottom: -4px;
	}

}

.carousel-inner {
	position: relative;
	overflow: hidden;
	width: 100%;
}

#myCarousel .carousel-inner .item, #myCarousel .carousel-inner {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: $pearl;
}

.iframe {
	z-index: -1;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	border: none;
}
