.btn-success {
    color: #fff;
    background-color: #337ab7;
    border-color: #286090
    
}

.btn-success.active, .btn-success:active, .btn-success:focus, .btn-success:hover, .open .dropdown-toggle.btn-success {
    color: #fff;
    background-color: #286090;
    border-color: #337ab7
}

.btn-success.active, .btn-success:active, .open .dropdown-toggle.btn-success {
    background-image: none
}

.btn-success.disabled, .btn-success.disabled.active, .btn-success.disabled:active, .btn-success.disabled:focus, .btn-success.disabled:hover, .btn-success[disabled], .btn-success[disabled].active, .btn-success[disabled]:active, .btn-success[disabled]:focus, .btn-success[disabled]:hover, fieldset[disabled] .btn-success, fieldset[disabled] .btn-success.active, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:hover {
    background-color: #337ab7;
    border-color: #286090
}

.btn-success .badge {
    color: #337ab7;
    background-color: #fff
}


.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
}

.btn-group.open .dropdown-toggle {
    box-shadow: none;
}