.view-client-table{
	td{
		padding-left: 0 !important;
		border-top: none !important;
		border-bottom: 1px solid $blue-grey;
		&:first-child{
			font-weight: 500;
			color: $grey;
			font-size: 15px;
		}
	}
}
.title-table-client{
	font-weight: 500;
	color: $grey;
	font-size: 15px;
	text-transform: none;
	font-family:'Gotham', sans-serif;
	margin: 0;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 0;
	padding-left: 0;
	border-bottom: 1px solid $blue-grey;
}
.btn-delete-client{
	background-color: transparent;
	padding-left: 0;
}

.btn-icon {
	padding: 0;

	&:not(:last-child) {
		margin-right: 10px;
	}
}
.region-client-table{
	width: 100%;
	tr:first-child{
		color: $blue;
		font-weight: 500;
	}
	td{
		border-bottom: 1px solid $blue-grey;
		padding: 8px 8px 8px 0;
		background-color: rgba(244, 245, 247, 0.22);
	}
}
.client-finance-table{
	width: 100%;
	td{
		border-bottom: 1px solid $blue-grey;
		padding: 8px 8px 8px 0;
		background: rgba(244, 245, 247, 0.22);
	}
}
.open-table {
	background-color: transparent;
	outline: none;
	margin-top: 6px;
	padding-right: 0;
	padding-left: 0;
}

.icon-open-table {
	font-size: 2.1rem;
	color: $blue;
	background: transparent;
	outline: none;
}
.icon-up {
	transform: rotate(180deg);
}

.toggle-div {
	transition: transform .2s ease-in-out;
}


.edit-client{
	width: 100%;
	display: flex;
	padding-left: 8rem;
	padding-right: 8rem;
	padding-top: 2rem;
	padding-bottom: 4rem;
	.list-group{
		padding-top: 1rem;
		.list-group-item{
			border: 1px solid $blue-grey;
			padding: 1.2rem;
			a{
				color: $dark-grey;
				font-weight: 400;
			}
		}
	}
	.container-button{
		padding-top: 2rem;
		.button-border{
			padding-left: 0;
		}
	}
	#edit-p{
		font-weight: 400;
		padding-top: 0;
		.badge{
			margin-left: 1rem;
			margin-right: 1rem;
			background-color: $blue;
		}
	}
	.add_new_city{
		display: flex;
		// align-items: center;
		flex-direction: column;
		padding-top: 2rem;
		.city_show {
			margin-right: 2rem;
		}
		.fa-minus{
			margin-right: 1rem;
			cursor: pointer;
			&:hover{
				color: $dark-red;
			}
		}
	}
	.menu-edit-client{
		margin-top: 2rem;
		width: 35%;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		cursor: pointer;

		ul{
			padding-left: 0;
			&.sub-menu{
				border-left: 1px solid $blue-grey;
				margin-left: .5rem;
			}
			.title-submenu{
				color: $blue;
				font-size: 16px;
				font-weight: 500;

			}
			li{
				margin-bottom: 2rem;
				list-style-type: none;
				&.sub-menu-item{
					a{
						font-size: 14px;
						margin-left: 2rem;
						&.active{
							font-weight: 400;
							&:before{
								display: none;
							}
						}
					}
				}
				a{
					color: $light-grey;
					font-size: 16px;
					font-weight: 400;
					&.active{
						position: relative;
						color:$blue;
						font-weight: 500;
					}
					&.active:before{
						content: "\f061";
						font-family: "Font Awesome 5 Pro";
						margin-right: 1rem;
						position: absolute;
						left: -3rem;

					}

				}
			}
		}
	}
	.label_types{
		border: 1px solid $blue-grey;
		padding: 1.2rem;
		border-radius: 3px;
		margin-right: 1rem;
	}
	.menu-client{
		display: flex;
		justify-content: space-between;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	.chosen-container{
		border: 1px solid $blue-grey;
    	padding: 1.2rem;
		border-radius: 3px;
		width: 100% !important;
		a{
			font-weight: 400;
		}
	}
	.regional-client{
		margin-top: 2rem;
		th{
			color: #5c5c5c;
			font-weight: 500;
			padding: 8px;
			padding-left: 0;
			border-top: none;
			border-bottom: 1px solid $blue-grey;
		}
		td{
			padding: 8px;
			padding-left: 0;
			border-top: 1px solid $blue-grey;
			border-bottom: 1px solid $blue-grey;
			vertical-align: middle;
		}

		button{
			background-color: transparent;
			padding-right: 0;
		}
	}
	.table-client{
		&.goal-detail{
			margin-bottom: 50px;
			thead{
				th{
					font-size: 1.4rem;
					padding-bottom: 10px;
					padding-left: 8px;
					&:first-child{
						padding-left: 0;
					}
				}
			}
			tr{
				td{
					vertical-align: middle;
					&.input-wrap{
						padding-bottom: 2rem;
					}
				}
			}
			button{
				background-color: transparent;
				padding-right: 0;
				.fa-plus{
					color: $blue;
					// margin-right: .5rem;
				}
			}
		}
		td, th {
			border: none;
			padding-top: 1rem;
			padding-bottom: 1rem;
			color: $grey;
			font-weight: 500;
			padding-right: 0;
			
		}
		input{
			border: 1px solid $blue-grey;
			padding: 1.2rem;
			border-radius: 3px;
			width: 100%;
			&::placeholder{
				font-size: 14px;

			}
			&[type=checkbox]{
				padding: 0;
			}
			&:disabled{
				background-color: $fog-grey;
			}
			&.special-input{
				padding: 1.130rem;
			}
			&.correction {
				padding: 0.6rem;
			}
		}

		p{
			color: $light-grey;
			padding-top: 1rem;
		}
		#client_legal_nalog{
			border: 1px solid $blue-grey;
			padding: 1.2rem;
			border-radius: 3px;
			width: 100%;
			color: $light-grey;
		}
		.multiselect.dropdown-toggle.btn{
			border: 1px solid $blue-grey !important;
			padding: 1.2rem;
			border-radius: 3px;
			.multiselect-selected-text{
				float: left;
			}
			.fa-sort{
				color: $blue-grey;
				margin-left: 0;
			}
		}
		.multiselect-container.dropdown-menu{
			width: 100%;
			background: $fog-grey;
			box-shadow: none;
			border: none;
			.checkbox input[type=checkbox]{
				margin-right: 1rem;
			}

		}
	}

	#client-edit-button{
		padding-top: 1rem;
		.city_show{
			font-weight: 400;
			color: #5c5c5c;
			padding-top: 1rem;
			padding-bottom: 1rem;
			.fa-minus{
				margin-right: 1rem;
				color: $red;
			}
		}
		button{
			padding-left: 0;
			padding-top: 1rem;
		}
	}
	#project_client_is_exclusion{
		padding: 0;
		margin-right: 2rem;
	}
	#client_transaction_paymentRecipient_chosen{
		border: none;
		width: 14rem !important;
	}
	.add_new_city{
		.city_show{
			padding-bottom: 1rem;
			font-weight: 400;
		}
	}
}
#main-client-page{
    /*i{
        margin-right: .5rem;
    }*/
    .fa-search{
        color: $blue;
    }
    .fa-pencil{
        color: $blue;
    }
    .fa-trash-alt{
        color: $red;
    }
}
#menu-main-client{
    .chosen-container{
        font-size: 14px;
        .search-choice{
            border: none;
            background: transparent;
            box-shadow: none;
            background-image: none;
            background-repeat: none;
        }
        .chosen-choices{
            background-color: none;
            &.active{
                background: none;
            }
        }
        input{
            color: $dark-grey;
        }
	}

}
.advertising-table{
	input{
		border: 1px solid $blue-grey;
		border-radius: 3px;
		padding: .5rem;
	}
}
.goals{
	border: 1px solid $pearl;
	padding: 20px;
	margin-bottom: 40px;

	#goal-table{
		margin-bottom: 1rem;
	}
	.goals-submenu{
		margin-bottom: 1rem;
		display: flex;
		justify-content: space-between;
		&.middle{
			margin-bottom: 2rem;
			margin-top: 2rem;
		}
		.subtitle-goal{
			font-size: 1.8rem;
			color: $grey;
			margin-right: 1rem;
			padding-left: 4rem;
			&:first-child{
				padding-left: 0;
			}
		}
		button{
			background-color: transparent;
			color: $blue;
			padding-right: 0;
			.fa-plus{
				color: $blue;
				margin-right: 1rem;
			}
		}
		.button-accent{
			background-color: $blue;
			color: $white;
			margin-right: 0;
		}
	}

	.channel-name {

	}
}
