@import 'fonts';
@import './colors';
@import './plugins';
@import './dropdown';
@import './context-window';
@import './client-page';
@import 'legacy.scss';
@import 'mediaplan-page';
@import 'map';
@import 'document-page';
@import 'orders-page';
@import 'admin';
@import 'select-react';
@import 'inputAutocomplete';
@import 'login';
@import 'calendar';
// @import '../public/css/design20';
@import 'button.scss';
// Файл с редизайном
@import './design';
